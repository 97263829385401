export const baseURL = "https://api.doctrue.in/api/v1";

export const DT_DEFAULT_UUID = "30368881-79e2-4ff3-9d7d-c376ea1e8f7e";

export const trialClinics = [
  "78f9f866-12f7-42a1-b440-086b4be00ada",
  "e7f4c69c-fea8-4c31-a2f4-15ff483791d1",
  "fe2c0239-7ef2-4e0c-a65c-545c418d068f",
  "17fbcc7f-37e7-493a-8ff8-f88d3c06fccb",
  "02775e36-547b-4e39-aa14-fd75aea718b8",
];

export const bimbaModelClinics = ["c2823789-282e-458a-8b26-e1002a48c543"];

export const adminAccess = [
  "830b6f09-c99f-40ed-85d2-84e2ae183578",
  "8054dc6e-a8bf-44a1-909e-188bf58ef675",
];

export const mentsReception = [
  "5a6155df-6b50-4621-a035-c1953bb0f249",
  "2ceb88d6-5227-4a04-bf7d-ea2f1c78c1f4",
];

export const estimateVisitTime = ["fa577fb4-6353-44ae-9a41-0d362d0ab5ce"];

export const noExtendSession = [
  "3635f86c-6baf-4fbc-99b1-b47d555afce0",
  "afa82b4b-8292-4423-811f-d3bf2226e482",
  "0492c7f0-39a2-4173-a214-ccd6a756127d",
  "4f43a787-adaf-46b2-9b34-97976023b22d",
  "bf9dd367-69ab-43bd-b0b1-ae3805a0fae6",
];
