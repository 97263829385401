import React from "react";
import { EditDaySessions } from "../../services/types";
import EditDayToggle from "./EditDayToggle";

const EditSession = ({
  schedule,
  setSchedule,
}: {
  schedule: {
    [key: number]: EditDaySessions;
  };
  setSchedule: React.Dispatch<
    React.SetStateAction<{
      [key: number]: EditDaySessions;
    }>
  >;
}) => {
  const week = [
    "",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSchedule((currentSchedule) => {
        const newSchedule = { ...currentSchedule };
        for (let day = 3; day <= 7; day++) {
          newSchedule[day] = {
            ...currentSchedule[2],
            sessions: {
              morning: {
                ...currentSchedule[2].sessions.morning,
                day_of_week: day,
                availability_id:
                  currentSchedule[day].sessions.morning.availability_id,
              },
              afternoon: {
                ...currentSchedule[2].sessions.afternoon,
                day_of_week: day,
                availability_id:
                  currentSchedule[day].sessions.afternoon.availability_id,
              },
              evening: {
                ...currentSchedule[2].sessions.evening,
                day_of_week: day,
                availability_id:
                  currentSchedule[day].sessions.evening.availability_id,
              },
            },
          };
        }
        return newSchedule;
      });
    }
  };

  return (
    <div className="md:mt-3">
      <div className="flex justify-end md:justify-between">
        <p className="font-light text-modalText text-sm mb-3 hidden md:flex">
          Session Availability
        </p>
        <div className="flex flex-row items-center mb-3 md:mb-0">
          <input
            type="checkbox"
            className="focus:ring-0 rounded border-0"
            onChange={handleChange}
          />
          <p className="text-modalText text-sm ml-1">Same for all week-days</p>
        </div>
      </div>
      <div className="space-y-3">
        {Object.keys(schedule).map((dayOfWeek) => (
          <EditDayToggle
            day={week[Number(dayOfWeek)]}
            dayOfWeek={Number(dayOfWeek)}
            weekSchedule={schedule}
            setSchedule={setSchedule}
            daySchedule={schedule[Number(dayOfWeek)]}
          />
        ))}
      </div>
    </div>
  );
};

export default EditSession;
