import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import CloseButton from "../../atoms/buttons/CloseButton";
import { Booking, Patient, Relation } from "../../../lib/utils/types";
import moment from "moment";
import {
  getPatientRelations,
  getPatientsByHospitalID,
  updatePatient,
} from "../../../lib/apis/patient";
import { toast } from "react-toastify";
import { usePatientBooking } from "../../../lib/contexts/PatientBookingContext";
import { getRouteSegment } from "../../../lib/utils/funcs";
import { updateBookingListByAvailabilityId } from "../../../lib/apis/booking";

const EditPatientModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  page,
  pageSize,
  search,
  fetchBookingsData,
  bookingData,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  page?: number;
  pageSize?: number;
  search?: string;
  fetchBookingsData?: () => Promise<void>;
  bookingData?: Array<Booking> | undefined;
}) => {
  const hospital_id = getRouteSegment(1);
  const { patient, setPatientsData } = usePatientBooking();

  const [patientState, setPatientState] = useState({
    full_name: patient?.full_name,
    contact_number: patient?.contact_number,
    gender: patient?.gender,
    email: patient?.email,
    address: patient?.address,
    date_of_birth: patient?.date_of_birth,
    patient_id: patient?.patient_id,
    booking_alias: "",
    booking_id: "",
  });

  useEffect(() => {
    if (patient && bookingData) {
      setPatientState({
        full_name: patient.full_name,
        contact_number:
          patient?.contact_number === null
            ? patient.manager_contact_number?.substring(2)
            : patient.contact_number.substring(2),
        gender: patient?.gender,
        email: patient?.email,
        address: patient?.address,
        date_of_birth: patient?.date_of_birth,
        patient_id: patient?.patient_id,
        booking_alias: bookingData?.filter(
          (e: Booking) => e.full_name === patient?.full_name
        )[0].booking_alias,
        booking_id: bookingData?.filter(
          (e: Booking) => e.full_name === patient?.full_name
        )[0].booking_id,
      });
    }
  }, [modalIsOpen]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (
      patientState.full_name &&
      patientState.contact_number &&
      patientState.gender &&
      patientState.full_name !== "" &&
      !isNaN(Number(patientState.contact_number)) &&
      String(patientState.contact_number).length === 10 &&
      patientState.patient_id
    ) {
      closeModal();

      if (patientState.booking_alias !== null) {
        const resp = await updateBookingListByAvailabilityId(
          patientState.booking_id,
          patientState.booking_alias === "" ? null : patientState.booking_alias
        );
        if (resp.status === 200) {
          toast.success("Name Updated successfully!");
        }
      }

      const req = {
        full_name: patientState.full_name,
        contact_number:
          patientState.contact_number.length === 10
            ? "91" + patientState.contact_number
            : patientState.contact_number,
        gender: patientState.gender,
        email: patientState.email || "",
        date_of_birth:
          patientState.date_of_birth || moment().format("YYYY-MM-DD"),
        address: patientState.address || "",
      };

      const res = await updatePatient(patientState.patient_id, req);
      if (res?.status === 200) {
        toast.success("Updated successfully!");
        if (page && pageSize && search) {
          const res = await getPatientsByHospitalID(
            hospital_id,
            page,
            pageSize,
            search
          );
          if (res?.status === 200) setPatientsData(res.data.result);
        } else if (fetchBookingsData) {
          await fetchBookingsData();
        }
      }
    } else if (patientState.full_name === "") {
      toast.error("Please enter patient name.");
    } else if (patientState.gender === "") {
      toast.error("Please enter patient gender.");
    } else if (patientState.contact_number === "") {
      toast.error("Please enter patient phone number.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  // useEffect(() => {
  //   const fetchRelations = async () => {
  //     if (patientState && patientState.patient_id) {
  //       try {
  //         const res = await getPatientRelations(patientState.patient_id);
  //         if (res?.status === 200) {
  //           const relations = res.data.result;
  //           console.log("relations", relations);
  //           setRelations(relations);
  //         }
  //       } catch (error) {
  //         console.log("Error fetching reports.");
  //       }
  //     }
  //   };

  //   fetchRelations();
  // }, [patientState]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Edit Patient Details</p>
        <CloseButton handleClick={() => closeModal()} />
      </div>

      {/* Body */}
      <form onSubmit={handleSubmit} className="mb-0">
        <div className="p-0 max-h-96 overflow-y-auto">
          <div className="px-6 py-5 bg-lightGrey">
            <div className="flex flex-col w-full">
              <p className="font-light text-modalText text-sm mb-1">
                Full Name
              </p>

              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter full name"
                value={patientState.full_name}
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    full_name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">
                Booking For
              </p>

              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter booking name"
                value={
                  patientState.booking_alias === null
                    ? patientState.full_name
                    : patientState.booking_alias
                }
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    booking_alias: e.target.value,
                  }))
                }
              />
            </div>

            <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">
                Phone Number
              </p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter phone number"
                value={String(patientState?.contact_number)}
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    contact_number: e.target.value,
                  }))
                }
                minLength={10}
                maxLength={10}
              />
            </div>
            <div className="flex flex-row mt-3">
              <div className="flex flex-col w-full mr-5">
                <p className="font-light text-modalText text-sm mb-1 ">
                  Date of Birth
                </p>
                <input
                  className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                  placeholder="Enter date of birth"
                  type="date"
                  value={moment(patientState?.date_of_birth).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={(e) =>
                    setPatientState((prevState) => ({
                      ...prevState,
                      date_of_birth: e.target.value,
                    }))
                  }
                  min="1954-01-01"
                  max={`${moment().format("YYYY-MM-DD")}`}
                />
              </div>
              <div className="flex flex-col w-full">
                <p className="font-light text-modalText text-sm mb-1">Gender</p>
                <div className="flex flex-row space-x-3">
                  <button
                    className={`${
                      patientState.gender?.toLowerCase() === "male"
                        ? "bg-editBG text-white"
                        : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                    } px-4 py-2 rounded-[20px]`}
                    onClick={() =>
                      setPatientState((prevState) => ({
                        ...prevState,
                        gender: "Male",
                      }))
                    }
                    type="button"
                  >
                    Male
                  </button>
                  <button
                    className={`${
                      patientState.gender?.toLowerCase() === "female"
                        ? "bg-editBG text-white"
                        : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                    } px-4 py-2 rounded-[20px]`}
                    onClick={() =>
                      setPatientState((prevState) => ({
                        ...prevState,
                        gender: "Female",
                      }))
                    }
                    type="button"
                  >
                    Female
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">Email</p>
              <input
                type="email"
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter email address"
                value={patientState.email}
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex flex-col w-full mt-3">
              <p className="font-light text-modalText text-sm mb-1">Address</p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter address"
                value={patientState.address}
                onChange={(e) =>
                  setPatientState((prevState) => ({
                    ...prevState,
                    address: e.target.value,
                  }))
                }
              />
            </div> */}
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
          <WhiteButton
            name="Cancel"
            handleClick={() => closeModal()}
            className="w-full h-14"
          />
          <DarkBlueButton
            name="Save"
            type="submit"
            className="w-full h-14"
            handleClick={handleSubmit}
          />
        </div>
      </form>
    </Modal>
  );
};

export default EditPatientModal;
