import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AvailabilitySlot, EditDaySessions } from "../../services/types";
import moment from "moment";
import EditSessionModal from "./modals/EditSessionModal";
import { modalStyles } from "../../assets/styles/modalStyles";
import EachDaySession from "../molecules/EachDaySession";
import { toast } from "react-toastify";
import { useUserData } from "../../lib/contexts/UserContext";
import {
  Calendar,
  DateRange,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import { useParams } from "react-router-dom";
import { getAvailabilityForCalendar } from "../../lib/apis/booking";
import Toggle from "react-toggle";
import HolidayModal from "./modals/HolidayModal";
import { Adjustment, SessionEvent } from "../../lib/utils/types";
import RangeHolidayModal from "./modals/RangeHolidayModal";

const SessionInfo = ({
  docAvail,
  setDocAvail,
  doctor_id,
}: {
  docAvail: AvailabilitySlot[] | undefined;
  setDocAvail: React.Dispatch<
    React.SetStateAction<AvailabilitySlot[] | undefined>
  >;
  doctor_id: string | undefined;
}) => {
  const { userData } = useUserData();
  const [visible, setVisible] = useState(false);
  const [holiday, setHoliday] = useState(false);
  const [rangeholiday, setRangeHoliday] = useState(false);
  const [session, setSession] = useState<SessionEvent>();
  const { mapping_id } = useParams();

  const week = [
    "",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const defaultDaySessions: EditDaySessions = {
    sessions: {
      morning: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
      afternoon: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
      evening: {
        availability_id: "",
        day_of_week: 0,
        start_time: "",
        end_time: "",
        max_patients_per_slot: 0,
        wait_time: { minutes: 0 },
        selected: false,
        max_wa_tokens: null,
        queue_type: "",
      },
    },
    selected: false,
  };

  const [weekSchedule, setWeekSchedule] = useState<{
    [key: number]: EditDaySessions;
  }>({
    1: {
      ...defaultDaySessions,
      sessions: {
        morning: {
          ...defaultDaySessions.sessions.morning,
          day_of_week: 1,
          selected: false,
        },
        afternoon: {
          ...defaultDaySessions.sessions.afternoon,
          day_of_week: 1,
          selected: false,
        },
        evening: {
          ...defaultDaySessions.sessions.evening,
          day_of_week: 1,
          selected: false,
        },
      },
      selected: false,
    },
    2: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 2 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
      },
      selected: false,
    },
    3: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 3 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
      },
      selected: false,
    },
    4: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 4 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
      },
      selected: false,
    },
    5: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 5 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
      },
      selected: false,
    },
    6: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 6 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
      },
      selected: false,
    },
    7: {
      ...defaultDaySessions,
      sessions: {
        morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
        afternoon: { ...defaultDaySessions.sessions.afternoon, day_of_week: 7 },
        evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
      },
      selected: false,
    },
  });

  const setData = () => {
    const temp: {
      [key: number]: EditDaySessions;
    } = {
      1: {
        ...defaultDaySessions,
        sessions: {
          morning: {
            ...defaultDaySessions.sessions.morning,
            day_of_week: 1,
            selected: false,
          },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 1,
            selected: false,
          },
          evening: {
            ...defaultDaySessions.sessions.evening,
            day_of_week: 1,
            selected: false,
          },
        },
        selected: false,
      },
      2: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 2 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 2,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 2 },
        },
        selected: false,
      },
      3: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 3 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 3,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 3 },
        },
        selected: false,
      },
      4: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 4 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 4,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 4 },
        },
        selected: false,
      },
      5: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 5 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 5,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 5 },
        },
        selected: false,
      },
      6: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 6 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 6,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 6 },
        },
        selected: false,
      },
      7: {
        ...defaultDaySessions,
        sessions: {
          morning: { ...defaultDaySessions.sessions.morning, day_of_week: 7 },
          afternoon: {
            ...defaultDaySessions.sessions.afternoon,
            day_of_week: 7,
          },
          evening: { ...defaultDaySessions.sessions.evening, day_of_week: 7 },
        },
        selected: false,
      },
    };

    if (docAvail !== undefined)
      for (const availability of docAvail) {
        const {
          availability_id,
          day_of_week,
          start_time,
          end_time,
          max_patients_per_slot,
          wait_time,
        } = availability;

        if (temp[day_of_week]) {
          if (
            moment(start_time, "HH:mm:ss").isBetween(
              moment("05:59:00", "HH:mm:ss"),
              moment("11:59:00", "HH:mm:ss")
            )
          ) {
            temp[day_of_week].sessions.morning.availability_id =
              availability_id;
            temp[day_of_week].sessions.morning.start_time = start_time;
            temp[day_of_week].sessions.morning.end_time = end_time;
            temp[day_of_week].sessions.morning.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.morning.wait_time = wait_time;
          } else if (
            moment(start_time, "HH:mm:ss").isBetween(
              moment("11:59:00", "HH:mm:ss"),
              moment("16:59:00", "HH:mm:ss")
            )
          ) {
            temp[day_of_week].sessions.afternoon.availability_id =
              availability_id;
            temp[day_of_week].sessions.afternoon.start_time = start_time;
            temp[day_of_week].sessions.afternoon.end_time = end_time;
            temp[day_of_week].sessions.afternoon.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.afternoon.wait_time = wait_time;
          } else {
            temp[day_of_week].sessions.evening.availability_id =
              availability_id;
            temp[day_of_week].sessions.evening.start_time = start_time;
            temp[day_of_week].sessions.evening.end_time = end_time;
            temp[day_of_week].sessions.evening.max_patients_per_slot =
              max_patients_per_slot;
            temp[day_of_week].sessions.evening.wait_time = wait_time;
          }
        }
      }

    setWeekSchedule({ ...temp });
  };

  useEffect(() => {
    // console.log("changed", docAvail);
    setData();
  }, [docAvail]);

  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState<
    {
      id: string;
      title: string;
      start: Date;
      end: Date;
      resourceId: string;
    }[]
  >([]);
  const [calendar, setCalendar] = useState(true);
  const [start, setStart] = useState<Date>(moment().startOf("month").toDate());
  const [end, setEnd] = useState<Date>(moment().endOf("month").toDate());

  const fetchNewAvailability = useCallback(
    async (start: Date, end: Date) => {
      if (mapping_id) {
        const res = await getAvailabilityForCalendar(
          mapping_id,
          moment(start).format("YYYY-MM-DD"),
          moment(end).format("YYYY-MM-DD")
        );
        if (res?.status === 200) {
          // console.log(res.data.result);
          return res.data.result;
        }
      }
    },
    [mapping_id]
  );
  const eventStyleGetter = (event: SessionEvent) => {
    let backgroundColor = "#3174ad"; // default color for available slots
    if (event.unavailable) {
      backgroundColor = "red"; // color for unavailable slots
    }
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0px",
        display: "block",
      },
    };
  };
  const generateEventsForMonth = useCallback(
    async (availabilityData: AvailabilitySlot[], start: Date, end: Date) => {
      const events: SessionEvent[] = [];
      const startOfMonth = moment(start).startOf("month");
      const endOfMonth = moment(end).endOf("month");

      const adjustments: Adjustment[] = await fetchNewAvailability(start, end);
      // console.log(adjustments);

      availabilityData.forEach((session, index) => {
        let currentDate = startOfMonth.clone().day(session.day_of_week - 1);
        if (currentDate.date() > 7) currentDate.add(7, "d");

        while (currentDate.isBefore(endOfMonth)) {
          const startTime = currentDate.clone().set({
            hour: parseInt(session.start_time.split(":")[0], 10),
            minute: parseInt(session.start_time.split(":")[1], 10),
          });
          const endTime = currentDate.clone().set({
            hour: parseInt(session.end_time.split(":")[0], 10),
            minute: parseInt(session.end_time.split(":")[1], 10),
          });

          const adjustment = adjustments?.find(
            (adjustment) =>
              adjustment.availability_id === session.availability_id &&
              currentDate.isSame(moment(adjustment.adjustment_date), "day")
          );

          if (adjustment) {
            if (adjustment.type === "Unavailability") {
              events.push({
                id: `${currentDate.format("YYYY-MM-DD")}`,
                title: `${startTime.format("hh:mmA")} - ${endTime.format(
                  "hh:mmA"
                )}`,
                start: startTime.toDate(),
                end: endTime.toDate(),
                resourceId: session.availability_id,
                adjustment_id: adjustment.adjustment_id,
                unavailable: true,
              });
              currentDate.add(7, "d");
              continue;
            } else if (adjustment.type === "Extension") {
              startTime.set({
                hour: parseInt(adjustment.session_start_time.split(":")[0], 10),
                minute: parseInt(
                  adjustment.session_start_time.split(":")[1],
                  10
                ),
              });
              endTime.set({
                hour: parseInt(adjustment.extended_end_time.split(":")[0], 10),
                minute: parseInt(
                  adjustment.extended_end_time.split(":")[1],
                  10
                ),
              });
            }
          }

          events.push({
            id: `${currentDate.format("YYYY-MM-DD")}`,
            title: `${startTime.format("hh:mmA")} - ${endTime.format(
              "hh:mmA"
            )}`,
            start: startTime.toDate(),
            end: endTime.toDate(),
            resourceId: session.availability_id,
            unavailable: false,
          });

          currentDate.add(7, "d");
        }
      });

      // console.log(events);
      return events;
    },
    [holiday]
  );

  const fetchAndSetEvents = async (start: Date, end: Date) => {
    if (docAvail) {
      const newEvents = await generateEventsForMonth(docAvail, start, end);
      setEvents(newEvents);
    }
  };
  // Usage within useEffect
  useEffect(() => {
    const fetchAndSetEventsOnDocAvail = async () => {
      const startOfMonth = moment().startOf("month").toDate();
      const endOfMonth = moment().endOf("month").toDate();
      await fetchAndSetEvents(startOfMonth, endOfMonth);
    };

    fetchAndSetEventsOnDocAvail();
  }, [docAvail, rangeholiday, holiday]);

  const handleRangeChange = async (range: Date[] | DateRange) => {
    // console.log("Range change:", range);
    let start: Date;
    let end: Date;

    if (Array.isArray(range)) {
      // Assuming the range is an array of dates (e.g., when calendar view is "week" or "day")
      start = range[0];
      end = range[range.length - 1];
    } else {
      // Assuming the range is an object with start and end dates (e.g., when calendar view is "month")
      start = range.start;
      end = range.end;
      setStart(start);
      setEnd(end);
    }

    if (docAvail) {
      const newEvents = await generateEventsForMonth(docAvail, start, end);
      setEvents(newEvents);
    }
  };

  const [selectedDates, setSelectedDates] = useState<DateRange | null>(null);

  const handleSelectSlot = ({ start, end }: DateRange) => {
    // console.log("Selected dates:", start, end);
    setSelectedDates({ start, end });
    setRangeHoliday(true);
  };

  return (
    <>
      <div className="bg-white flex flex-col mt-6 rounded-lg mb-10">
        <div className="flex flex-row justify-between items-center rounded-t-lg border-[1px] border-b-doctorsBorder px-5 py-4">
          <p className="text-dark font-medium">Session Details</p>
          <div>
            <p className="text-xs text-docDetail">Calendar View</p>
            <Toggle
              icons={false}
              checked={calendar}
              onChange={async () => setCalendar(!calendar)}
            />
          </div>
          {userData?.role === "MasterAdmin" && !calendar && (
            <button
              className="bg-editBG text-white px-5 py-[6px] rounded-[20px]"
              onClick={() => {
                if (docAvail !== undefined) setVisible(true);
                else toast.error("Sessions data loading.");
              }}
            >
              Edit
            </button>
          )}
        </div>
        {calendar ? (
          <div style={{ height: 500 }}>
            <Calendar
              dayLayoutAlgorithm="no-overlap"
              defaultDate={new Date()}
              defaultView={Views.MONTH}
              localizer={localizer}
              events={events}
              eventPropGetter={eventStyleGetter}
              //@ts-ignore
              onRangeChange={handleRangeChange}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={(event) => {
                // console.log(event);
                setSession(event);
                setHoliday(true);
              }}
              selectable
            />
          </div>
        ) : (
          <div className="rounded-b-lg px-4 border-[1px] border-t-0 border-doctorsBorder">
            {Object.keys(weekSchedule).map((dayOfWeek) => (
              <EachDaySession
                day={week[Number(dayOfWeek)]}
                daySchedule={weekSchedule[Number(dayOfWeek)]}
              />
            ))}
          </div>
        )}
      </div>
      <EditSessionModal
        modalIsOpen={visible}
        closeModal={() => setVisible(false)}
        customStyles={modalStyles}
        docAvail={docAvail}
        setDocAvail={setDocAvail}
      />
      {holiday && (
        <HolidayModal
          modalIsOpen={holiday}
          closeModal={() => setHoliday(false)}
          customStyles={modalStyles}
          session={session}
          docAvail={docAvail}
          startDate={start}
          endDate={end}
          generateEventsForMonth={generateEventsForMonth}
          setEvents={setEvents}
        />
      )}

      {rangeholiday && (
        <RangeHolidayModal
          modalIsOpen={rangeholiday}
          closeModal={() => {
            setRangeHoliday(false);
          }}
          customStyles={modalStyles}
          session={session}
          docAvail={docAvail}
          doctor_id={doctor_id}
          startDate={String(selectedDates?.start)}
          endDate={String(selectedDates?.end)}
          generateEventsForMonth={generateEventsForMonth}
          setEvents={setEvents}
        />
      )}
    </>
  );
};

export default SessionInfo;
