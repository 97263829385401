import React, { useEffect, useState } from "react";
import {
  DownArrow,
  LeftArrow,
  LeftArrowWithTail,
  Menu,
  RightArrow,
  Search,
} from "../../assets/icons/Icons";
import { DocProfileData } from "../../services/types";
import { useNavigate, useParams } from "react-router-dom";
import ProfilePicture from "../atoms/ProfilePicture";
import Toggle from "react-toggle";
import moment from "moment";
import SwitchBranch from "./modals/SwitchBranch";
import { modalStyles, switchBranch } from "../../assets/styles/modalStyles";
import MobileSidebar from "./MobileSidebar";
import { useHospDocData } from "../../lib/contexts/HospitalDoctorContext";
import ClinicModal from "./modals/HospModal";
import OverflowString from "../atoms/OverflowString";
import { getCheckInDoctor, setCheckInDoctor } from "../../lib/apis/doctor";
import { isFirebaseStorageUrl } from "../../lib/utils/funcs";

const Header = ({
  header,
  value,
  docData,
  data,
}: {
  header?: boolean;
  value?: string;
  docData?: DocProfileData;
  data?: {
    mapping_id: string;
    date: string;
    session: string;
  };
}) => {
  const navigate = useNavigate();
  const { allHospData, hospitalID, hospData, doctorsData } = useHospDocData();
  const params = useParams();
  const mapping_id = params.mapping_id;

  const [switchVisible, setSwitchVisible] = useState(false);
  const [clinicVisible, setClinicVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [available, setAvailable] = useState(false);
  const [hoveredDoctorId, setHoveredDoctorId] = useState("");
  const [checkIndata, setCheckInData] = useState({
    mapping_id: "",
    date: "",
    session: "",
  });

  const [search, setSearch] = useState("");

  // Further filter by search query if there's any

  const filteredAndSearchedDoctors =
    search !== ""
      ? doctorsData?.filter((doc) =>
          doc.full_name.toLowerCase().includes(search.toLowerCase())
        )
      : [];

  const docAvail = async () => {
    const res = await getCheckInDoctor(
      checkIndata.mapping_id,
      checkIndata.date,
      checkIndata.session
    );
    if (res?.status === 200) {
      console.log(res.data.result.doc_in);
      setAvailable(res.data.result.doc_in);
    } else {
      setAvailable(false);
    }
  };

  useEffect(() => {
    if (data !== undefined && String(data?.session)) {
      setCheckInData(data);
      docAvail();
    }
  }, [data]);

  if (header)
    return (
      <div className="bg-white h-[64px] px-5 lg:pl-7 lg:pr-11 lg:py-5 flex justify-between items-center border-solid border-b-[1px] border-sbBorder">
        <div className="flex flex-row items-center">
          <button
            className="flex lg:hidden"
            onClick={() => setMenuVisible(true)}
          >
            <Menu />
          </button>
          <p className="font-semibold text-xl ml-3 md:ml-5 lg:ml-0">{value}</p>
        </div>
        <div className="flex flex-row items-center">
          <button
            onClick={() => {
              if (allHospData) {
                if (allHospData.length > 1) {
                  setSwitchVisible(true);
                  setClinicVisible(false);
                } else if (hospData !== undefined) {
                  setSwitchVisible(false);
                  setClinicVisible(true);
                } else {
                  setClinicVisible(false);
                  setSwitchVisible(false);
                }
              }
            }}
            className="flex flex-row items-center ml-4"
          >
            {hospData?.logo === "test" || hospData?.logo === "" ? (
              <ProfilePicture username={hospData.hospital_name} />
            ) : (
              <img
                className={`w-10 h-10 rounded-full`}
                src={hospData?.logo}
                alt="hospital-logo"
              ></img>
            )}
            <p className="font-medium text-sm ml-2 lg:ml-2 mr-3">
              {hospData ? hospData.hospital_name : ""}
            </p>
            {allHospData && allHospData.length > 1 && <DownArrow />}
          </button>
        </div>
        <SwitchBranch
          modalIsOpen={switchVisible}
          closeModal={() => setSwitchVisible(false)}
          customStyles={switchBranch}
        />
        <ClinicModal
          modalIsOpen={clinicVisible}
          closeModal={() => setClinicVisible(false)}
          customStyles={modalStyles}
        />
        {menuVisible && (
          <MobileSidebar visible={menuVisible} setVisible={setMenuVisible} />
        )}
      </div>
    );

  const tabsBox: HTMLElement | null = document.querySelector(".tabs-box2");
  if (tabsBox !== null) {
    const arrowIcons: NodeListOf<Element> =
      document.querySelectorAll(".icon svg");

    let isDragging: boolean = false;

    const handleIcons = (scrollVal: number): void => {
      let maxScrollableWidth: number =
        tabsBox.scrollWidth - tabsBox.clientWidth;

      (arrowIcons[0].parentElement as HTMLElement).style.display =
        scrollVal <= 0 ? "none" : "flex";
      (arrowIcons[1].parentElement as HTMLElement).style.display =
        maxScrollableWidth - scrollVal <= 1 ? "none" : "flex";
    };

    arrowIcons.forEach((icon: Element) => {
      icon.addEventListener("click", () => {
        let scrollWidth: number = (tabsBox.scrollLeft +=
          icon.id === "left" ? -101 : 101);

        handleIcons(scrollWidth);
      });
    });

    const dragging = (e: MouseEvent): void => {
      if (!isDragging) return;
      tabsBox.classList.add("dragging");
      tabsBox.scrollLeft -= e.movementX;
      handleIcons(tabsBox.scrollLeft);
    };

    const dragStop = (): void => {
      isDragging = false;
      tabsBox.classList.remove("dragging");
    };

    tabsBox.addEventListener("mousedown", () => (isDragging = true));
    tabsBox.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
  }
  return (
    <div className="bg-white h-[64px] px-5 lg:pl-7 lg:pr-11 lg:py-5 flex justify-between items-center border-solid border-b-[1px] border-sbBorder">
      <div className="flex flex-row items-center">
        <button className="flex lg:hidden" onClick={() => setMenuVisible(true)}>
          <Menu />
        </button>
        <button
          className="hidden lg:flex"
          onClick={() => navigate("/" + hospitalID + "/doctors")}
        >
          <LeftArrowWithTail />
        </button>
        {docData?.profile_picture === docData?.full_name ||
        isFirebaseStorageUrl(docData?.profile_picture) ? (
          <ProfilePicture
            username={docData?.full_name ? docData?.full_name : ""}
            className={"mx-2"}
          />
        ) : (
          <img
            className={`w-10 h-10 rounded-full mx-2 hidden md:flex`}
            src={docData?.profile_picture}
            alt="doctor"
          ></img>
        )}
        <div className="flex flex-col mx-2 md:ml-0">
          <div className="flex flex-row items-center">
            <p className="font-semibold text-lg">{docData?.full_name}</p>
          </div>
          <p className="text-xs text-docDetail">
            <OverflowString
              text={docData?.qualification + ", " + docData?.specialization}
              length={40}
            />
          </p>
        </div>
        {data &&
          data.date === moment().format("YYYY-MM-DD") &&
          data.session !== "undefined" && (
            <div className="flex flex-col ml-3 md:ml-5">
              <p className="text-xs text-docDetail">Check-In</p>
              <Toggle
                icons={false}
                defaultChecked={available}
                checked={available}
                onChange={async () => {
                  setAvailable(true);
                  const res = await setCheckInDoctor(
                    checkIndata.mapping_id,
                    checkIndata.date,
                    checkIndata.session,
                    true
                  );
                  if (res?.status === 200) console.log(res.data.result);
                }}
                disabled={available}
              />
            </div>
          )}
      </div>
      <div className="flex lg:gap-3 xl:gap-3">
        <div className="hidden md:flex">
          <div className="wrapper px-6 md:w-56 lg:w-60 text-queueText text-sm font-medium z-20">
            <div
              className={
                doctorsData && doctorsData?.length > 7
                  ? "icon cursor-pointer  flex"
                  : "icon cursor-pointer  hidden"
              }
              style={{ width: "max-width" }}
            >
              <LeftArrow />
            </div>
            <div
              className="tabs-box2 space-x-3 overflow-y-hidden md:pl-2 lg:pl-4"
              style={{ gap: "8px" }}
            >
              {doctorsData &&
                doctorsData
                  .filter((doctor) => doctor.mapping_id !== mapping_id)
                  .map((doctor) => (
                    <button
                      key={doctor.doctor_id}
                      onMouseEnter={() => setHoveredDoctorId(doctor.mapping_id)}
                      onMouseLeave={() => setHoveredDoctorId("")}
                      onClick={() =>
                        navigate(
                          "/" + hospitalID + "/doctors/doc/" + doctor.mapping_id
                        )
                      }
                      className="flex flex-col items-center pt-2"
                      style={{ margin: "0" }}
                    >
                      {doctor?.profile_picture === doctor?.full_name ||
                      doctor?.profile_picture === "pfp" ? (
                        <div className="headCard w-12">
                          <div className="headCard-info flex justify-center">
                            <ProfilePicture
                              username={
                                doctor?.full_name ? doctor?.full_name : ""
                              }
                              className={`${
                                doctorsData.length < 10 ? "mx-2" : "mx-1"
                              } w-9 h-9`}
                            />
                          </div>
                          <ul className="headCard-social">
                            <li className="headCard-social__item">
                              <div className="headCard-subtitle relative bottom-5 text-sm">
                                {doctor.full_name.split(" ")[0]}
                              </div>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="headCard w-12">
                          <div className="headCard-info flex justify-center">
                            <img
                              className={`w-9 h-9 rounded-full ${
                                doctorsData.length < 10 ? "mx-2" : "mx-1"
                              } hidden md:flex`}
                              src={doctor?.profile_picture}
                              alt="doctor"
                            ></img>
                          </div>
                          <ul className="headCard-social">
                            <li className="headCard-social__item">
                              <div className="headCard-subtitle relative bottom-5 text-sm">
                                {doctor.full_name.split(" ")[0]}
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                      {/* {hoveredDoctorId === doctor.mapping_id && (
                        <p className="text-xs text-docDetail mx-1">
                          {doctor.full_name.split(" ")[0]}
                        </p>
                      )} */}
                    </button>
                  ))}
            </div>

            <div
              className={
                doctorsData && doctorsData?.length > 7
                  ? "icon cursor-pointer  flex pl-2"
                  : "icon cursor-pointer  hidden"
              }
              style={{ width: "max-width" }}
            >
              <RightArrow />
            </div>
          </div>
          <div className=" flex-col justify-center hidden md:flex">
            <div className="flex items-center rounded-lg border-[0.5px] border-sbBorder bg-white ml-4 h-max">
              <input
                className="border-0 p-0 px-4 py-2 border-sbBorder opacity-80 rounded-lg focus:outline-none md:w-20 lg:w-40 xl:w-60"
                type="text"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <p className="w-10">
                <Search />
              </p>
            </div>
            {search !== "" && (
              <div className="p-4 px-0 rounded-lg bg-white  absolute shadow-lg top-16 md:w-32 lg:w-52 xl:w-72 ml-4 z-20">
                {" "}
                {filteredAndSearchedDoctors
                  ?.sort(
                    (a, b) =>
                      moment(a.created_at).valueOf() -
                      moment(b.created_at).valueOf()
                  )
                  ?.map((doctor, ind) => (
                    <>
                      {ind < 5 && (
                        <div
                          className="flex gap-4 items-center  hover:bg-queueHover hover:text-white rounded-md p-2 cursor-pointer"
                          onClick={() => {
                            setSearch("");
                            navigate(
                              "/" +
                                hospitalID +
                                "/doctors/doc/" +
                                doctor.mapping_id
                            );
                          }}
                        >
                          {doctor?.profile_picture === doctor?.full_name ||
                          doctor?.profile_picture === "pfp" ? (
                            <ProfilePicture
                              username={
                                doctor?.full_name ? doctor?.full_name : ""
                              }
                              className={"mx-2 hidden lg:flex"}
                            />
                          ) : (
                            <img
                              className={`w-10 h-10 rounded-full mx-2 hidden lg:flex`}
                              src={doctor?.profile_picture}
                              alt="doctor"
                            ></img>
                          )}
                          <div>
                            <p className="text-md font-semibold">
                              {doctor.full_name}
                            </p>
                            <p className="text-sm">{doctor.specialization}</p>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                {filteredAndSearchedDoctors?.length === 0 && (
                  <div className="flex gap-4 items-center justify-center">
                    <div>
                      <p className="text-md">No doctor found</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center">
          <button
            onClick={() => {
              if (allHospData) {
                if (allHospData.length > 1) {
                  setSwitchVisible(true);
                  setClinicVisible(false);
                } else if (hospData !== undefined) {
                  setSwitchVisible(false);
                  setClinicVisible(true);
                } else {
                  setClinicVisible(false);
                  setSwitchVisible(false);
                }
              }
            }}
            className="flex flex-row items-center ml-4"
          >
            {hospData?.logo === "test" || hospData?.logo === "" ? (
              <ProfilePicture username={hospData.hospital_name} />
            ) : (
              <img
                className={`w-10 h-10 rounded-full`}
                src={hospData?.logo}
                alt="hospital-logo"
              ></img>
            )}
            <p className="font-medium text-sm ml-2 lg:ml-2 mr-3 hidden md:flex">
              {hospData ? hospData.hospital_name : ""}
            </p>
            {allHospData && allHospData.length > 1 && <DownArrow />}
          </button>
        </div>
      </div>
      <SwitchBranch
        modalIsOpen={switchVisible}
        closeModal={() => setSwitchVisible(false)}
        customStyles={switchBranch}
      />
      <ClinicModal
        modalIsOpen={clinicVisible}
        closeModal={() => setClinicVisible(false)}
        customStyles={modalStyles}
      />
      {menuVisible && (
        <MobileSidebar visible={menuVisible} setVisible={setMenuVisible} />
      )}
    </div>
  );
};

export default Header;
