import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import CloseButton from "../../atoms/buttons/CloseButton";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import { SessionEvent } from "../../../lib/utils/types";
import ReactDropdown from "react-dropdown";
import { generateTimeOptions } from "../../../lib/utils/funcs";
import Toggle from "react-toggle";
import {
  addUnavailablity,
  deleteUnavailablity,
  extendSession,
  getBookingListByAvailabilityId,
  updateBookingStatus,
} from "../../../lib/apis/booking";
import moment from "moment";
import { AvailabilitySlot } from "../../../services/types";
import WhiteButton from "../../atoms/buttons/WhiteButton";
import RescheduleModal from "./RescheduleModal";
import { modalStyles } from "../../../assets/styles/modalStyles";

const HolidayModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  session,
  startDate,
  endDate,
  docAvail,
  generateEventsForMonth,
  setEvents,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  session: SessionEvent | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  docAvail: AvailabilitySlot[] | undefined;
  generateEventsForMonth: (
    availabilityData: AvailabilitySlot[],
    start: Date,
    end: Date
  ) => Promise<SessionEvent[]>;
  setEvents: (value: React.SetStateAction<SessionEvent[]>) => void;
}) => {
  const [working, setWorking] = useState(!session?.unavailable);
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [time, setTime] = useState("morning");
  const [bookingId, setBookingId] = useState("");
  const [start, setStart] = useState(session?.title.split(" - ")[0]);
  const [end, setEnd] = useState(session?.title.split(" - ")[1]);

  console.log(session);

  const [bookings, setBookings] = useState([
    {
      full_name: "",
      gender: "",
      contact_number: "",
      manager_contact_number: "",
      booking_id: "",
    },
  ]);

  const fetchBookingsData = async () => {
    const booked_data = await getBookingListByAvailabilityId(
      session?.resourceId,
      String(session?.id)
    );
    if (booked_data?.status === 200) {
      if (
        booked_data.data.result.filter(
          (e: any) =>
            e.status === 0 &&
            moment().format("YYYY-MM-DD") === e.booking_time.substring(0, 10)
        ).length !== 0
      ) {
        setBookings(
          booked_data.data.result.filter(
            (e: any) =>
              e.status === 0 &&
              moment().format("YYYY-MM-DD") === e.booking_time.substring(0, 10)
          )
        );

        console.log(
          "bookings",
          booked_data.data.result.filter(
            (e: any) =>
              e.status === 0 &&
              moment().format("YYYY-MM-DD") === e.booked_date.substring(0, 10)
          )
        );
      } else {
        setBookings([
          {
            full_name: "",
            gender: "",
            contact_number: "",
            manager_contact_number: "",
            booking_id: "",
          },
        ]);
      }
    } else {
      setBookings([
        {
          full_name: "",
          gender: "",
          contact_number: "",
          manager_contact_number: "",
          booking_id: "",
        },
      ]);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fetchBookingsData();

      setStart(session?.title.split(" - ")[0]);
      setEnd(session?.title.split(" - ")[1]);

      if (
        (Number(session?.title.split(" - ")[0]?.split(":")[0]) === 12 ||
          Number(session?.title.split(" - ")[0]?.split(":")[0]) >= 1) &&
        Number(session?.title.split(" - ")[0]?.split(":")[0]) < 5 &&
        session?.title.split(" - ")[0]?.split(":")[1].substring(2, 5) === "PM"
      ) {
        setTime("afternoon");
      } else if (
        Number(session?.title.split(" - ")[0]?.split(":")[0]) >= 5 &&
        session?.title.split(" - ")[0]?.split(":")[1].substring(2, 5) === "PM"
      ) {
        setTime("evening");
      } else {
        setTime("morning");
      }
    }, 500);
  }, [session, rescheduleVisible]);

  const handleCancelAll = () => {
    bookings.map(async (data, ind) => {
      await updateBookingStatus({
        bookingId: data.booking_id,
        status: 4,
      });
      if (ind === bookings.length - 1) {
        toast.success("All Bookings Cancelled");
        if (session !== undefined) {
          const res = await addUnavailablity(session.resourceId, session.id);
          console.log(res);
          if (res?.status === 200) {
            toast.success("Unavailability added!");
            console.log(docAvail, startDate, endDate);
            if (docAvail && startDate && endDate) {
              const newEvents = await generateEventsForMonth(
                docAvail,
                startDate,
                endDate
              );
              console.log(newEvents);
              setEvents(newEvents);
            }
            setWorking(true);
            closeModal();
          }
        }
      }
    });
  };
  console.log(docAvail);
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Edit Session Details</p>
        <CloseButton
          handleClick={() => {
            setWorking(true);
            closeModal();
          }}
        />
      </div>

      {/* Body */}
      <div className="p-0 overflow-y-auto max-h-96">
        <div className="px-6 py-5 bg-lightGrey space-y-3 pb-20">
          {working && (
            <>
              <p className="text-modalText text-sm">
                Change the session timings as required
              </p>
              <div className="flex flex-row space-x-3 !mb-5">
                <div className="flex flex-col">
                  <p className="font-light text-modalText text-sm mb-1">
                    Start Time
                  </p>
                  <ReactDropdown
                    options={generateTimeOptions(time)}
                    value={start}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      setStart(String(selectedOption.label));
                    }}
                    placeholder="Select time"
                    className="w-48"
                    controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
                  />
                </div>
                <p className="flex flex-col-reverse text-docDetail mb-2">to</p>
                <div className="flex flex-col">
                  <p className="font-light text-modalText text-sm mb-1">
                    End Time
                  </p>
                  <ReactDropdown
                    options={generateTimeOptions(time)}
                    value={end}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      setEnd(String(selectedOption.label));
                    }}
                    placeholder="Select time"
                    className="w-48"
                    controlClassName="!rounded-lg !border-[0.5px] !border-sbBorder"
                  />
                </div>
              </div>
            </>
          )}
          <p className="text-modalText text-sm">
            Turn {working ? "off" : "on"} the toggle to set{" "}
            {working ? "un" : ""}availability
          </p>
          <div className="flex items-center">
            <p
              className={`mx-2 text-sm ${
                working ? "text-dark" : "text-docDetail"
              }`}
            >
              Doctor {working ? "Available" : "Unavailable"}
            </p>
            <Toggle
              icons={false}
              checked={working}
              onChange={() => setWorking(!working)}
            />
          </div>
          {!working &&
            bookings[0].full_name !== "" &&
            bookings[0].gender !== "" && (
              <div className="p-2">
                <h1 className="font-bold">Bookings</h1>
                <table>
                  <tr>
                    <th className="px-0 text-md">Name</th>
                    <th>Gender</th>
                    <th>Phone Number</th>
                    <th className="!relative">Action</th>
                  </tr>
                  {bookings.map((e) => (
                    <tr>
                      <td className="px-0 py-1 text-sm">{e.full_name}</td>
                      <td className="py-1 text-sm">{e.gender}</td>
                      <td className="py-1 text-sm">
                        {e.contact_number === null
                          ? e.manager_contact_number.substring(2)
                          : e.contact_number.substring(2)}
                      </td>
                      <td className="py-1">
                        <DarkBlueButton
                          className="w-max text-sm !px-2"
                          name="Reschedule"
                          handleClick={() => {
                            setBookingId(e.booking_id);
                            setRescheduleVisible(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}
        </div>
      </div>

      {/* Footer */}
      <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
        <WhiteButton
          name="Close"
          className="w-full h-14"
          handleClick={() => {
            setWorking(true);
            closeModal();
          }}
        />
        {((bookings[0].full_name === "" && bookings[0].gender === "") ||
          working) && (
          <DarkBlueButton
            className="w-full h-14"
            name="Save"
            handleClick={async () => {
              if (session && start && end) {
                if (working) {
                  if (session.adjustment_id) {
                    const res = await deleteUnavailablity(
                      session.adjustment_id
                    );

                    if (res?.status === 200) {
                      toast.success("Unavailability removed");
                    } else {
                      toast.error(
                        "Error occured while removing unavailability"
                      );
                    }
                  }
                  if (
                    start !== session?.title.split(" - ")[0] ||
                    end !== session?.title.split(" - ")[1]
                  ) {
                    const req = {
                      availability_id: session?.resourceId,
                      adjustment_date: session?.id,
                      session_start_time: moment(start, "HH:mmA").format(
                        "HH:mm:ss"
                      ),
                      extended_end_time: moment(end, "HH:mmA").format(
                        "HH:mm:ss"
                      ),
                    };

                    const res = await extendSession(req);
                    if (res?.status === 200) {
                      console.log(res.data.result);
                      closeModal();
                      toast.success("Updated successfully!");
                      setWorking(true);
                      if (docAvail && startDate && endDate) {
                        const newEvents = await generateEventsForMonth(
                          docAvail,
                          startDate,
                          endDate
                        );
                        setEvents(newEvents);
                      }
                    }
                  } else {
                    closeModal();
                  }
                } else {
                  const res = await addUnavailablity(
                    session.resourceId,
                    session.id
                  );

                  if (res?.status === 200) {
                    toast.success("Unavailability added!");
                    console.log(docAvail, startDate, endDate);
                    if (docAvail && startDate && endDate) {
                      const newEvents = await generateEventsForMonth(
                        docAvail,
                        startDate,
                        endDate
                      );
                      console.log(newEvents);
                      setEvents(newEvents);
                    }
                    setWorking(true);
                    closeModal();
                  }
                }
              }
            }}
          />
        )}
        {bookings[0].full_name !== "" &&
          bookings[0].gender !== "" &&
          !working && (
            <>
              <DarkBlueButton
                className="w-full h-14 !bg-red-500"
                name="Cancel All"
                handleClick={handleCancelAll}
              />
              {/* <DarkBlueButton
                className="w-full h-14"
                name="Reschedule"
                handleClick={() => {
                  setRescheduleVisible(true);
                }}
              /> */}
            </>
          )}
        {rescheduleVisible && (
          <RescheduleModal
            modalIsOpen={rescheduleVisible}
            closeModal={() => {
              setRescheduleVisible(false);
              fetchBookingsData();
            }}
            customStyles={modalStyles}
            session={session?.resourceId}
            booking_id={bookingId}
            type="each"
            booking_ids={bookings}
            mappingId={undefined}
          />
        )}
      </div>
    </Modal>
  );
};

export default HolidayModal;
