import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { useParams } from "react-router-dom";
import { AvailabilitySlot } from "../services/types";
import BasicInfo from "../components/molecules/BasicInfo";
import SessionInfo from "../components/organisms/SessionInfo";
import Loader from "../components/molecules/Loader";
import { useUserData } from "../lib/contexts/UserContext";
import { getDoctorAvailability } from "../lib/apis/doctor";

const DocDetails = () => {
  const [docAvail, setDocAvail] = useState<AvailabilitySlot[]>();
  const { userData } = useUserData();

  const param = useParams();
  const mapping_id = String(param.mapping_id);
  const { docDetails, setDocDetails } = useHospDocData();
  // console.log("docDetails", docDetails);

  // useEffect(() => {
  //   setChildHospitalID(id);
  // }, [id]);

  useEffect(() => {
    const fetchDocData = async () => {
      const res = await getDoctorAvailability(mapping_id);
      if (res?.status === 200) {
        setDocAvail(res.data.result.doctor_availability);
        setDocDetails(res.data.result.doctor_details);
      }
    };
    fetchDocData();
  }, [mapping_id, setDocDetails]);
  return (
    <div className="flex flex-col w-full">
      <Header docData={docDetails} />
      <div className="pt-6 px-7">
        <BasicInfo data={docDetails} setData={setDocDetails} />
        <SessionInfo
          docAvail={docAvail}
          setDocAvail={setDocAvail}
          doctor_id={docDetails?.doctor_id}
        />
      </div>
      {userData === undefined && <Loader />}
    </div>
  );
};

export default DocDetails;
