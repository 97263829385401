import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { MdDelete } from "react-icons/md";
import { RiEdit2Fill } from "react-icons/ri";
import Header from "../components/organisms/Header";
import { PatientState, SessionAdjustment } from "../services/types";
import DarkBlueButton from "../components/atoms/buttons/DarkBlueButton";
import { IoIosArrowForward } from "react-icons/io";
import { RightArrow, Search } from "../assets/icons/Icons";
import NotFound from "../components/atoms/buttons/NotFound";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import {
  estimateVisitTime,
  mentsReception,
  noExtendSession,
} from "../lib/utils/constants";
import { getRouteSegment, isFirebaseStorageUrl } from "../lib/utils/funcs";
import { Option } from "react-dropdown";
import moment from "moment";
import SelectSlot from "../components/atoms/SelectSlot";
import SelectToken from "../components/atoms/SelectToken";
import {
  addBooking,
  checkAvailability,
  extendSession,
  getSlots,
  getTokens,
} from "../lib/apis/booking";
import { useUserData } from "../lib/contexts/UserContext";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import { getDoctorAvailability } from "../lib/apis/doctor";
import { DocAvailability, Relation } from "../lib/utils/types";
import {
  addRelation,
  createPatient,
  deleteRelation,
  getPatientByPhoneNo,
  getPatientRelations,
  updatePatient,
  updateRelation,
} from "../lib/apis/patient";
import { toast } from "react-toastify";
import WhiteButton from "../components/atoms/buttons/WhiteButton";
import { modalStyles } from "../assets/styles/modalStyles";
import CloseButton from "../components/atoms/buttons/CloseButton";
import { useLocation } from "react-router-dom";
import ProfilePicture from "../components/atoms/ProfilePicture";
import Loader from "../components/molecules/Loader";

const AppointmentBooking = () => {
  const { userData } = useUserData();
  const { doctorsData } = useHospDocData();
  const { setIndex, SelectedDate, setSelectedDate } = usePatientBooking();
  const hospital_id = getRouteSegment(1);
  const location = useLocation();
  const [load, setLoad] = useState(true);
  const [relNumber, setRelNumber] = useState("");

  const [patient, setPatient] = useState<PatientState>({
    full_name: "",
    contact_number: "",
    gender: "",
    patient_id: "",
    registered: false,
  });
  const [formState, setFormState] = useState({
    comments: "",
    type: "booking",
    currentIndex: 0,
    allSlots: [""],
    slicedSlots: [""],
    allTokens: [0],
    slicedTokens: [0],
    index: moment(SelectedDate).day() + 1,
    docSession: {
      value: "",
      label: "",
    },
  });

  const [relations, setRelations] = useState<Relation[]>();
  const [doctor_id, setDoctor_id] = useState<string>();
  const [relModal, setRelModal] = useState<boolean>(false);
  const [addRel, setAddRel] = useState<boolean>(false);
  const [editRel, setEditRel] = useState<boolean>(false);
  const [slotModal, setSlotModal] = useState<boolean>(false);
  const [docModal, setDocModal] = useState<boolean>(false);
  const [bookingFor, setBookingFor] = useState<string>();
  const [mappingID, setMappingID] = useState<string>();
  const [allSessions, setAllSessions] = useState<Option[] | undefined>();
  const [bookingSlot, setBookingSlot] = useState<string>();
  const [token, setToken] = useState<number>();
  const [relationStep, setRelationStep] = useState("");
  const [queue_type, setQueue_type] = useState("");
  const [type, setType] = useState("");
  const [extend, setExtend] = useState(30);
  const [sessionExp, setSessionExp] = useState(false);
  const [relationToAdd, setRelationToAdd] = useState<{
    full_name: string;
    contact_number: string;
    gender: string;
  }>({
    full_name: "",
    contact_number: "",
    gender: "Male",
  });

  const [editDeatils, setEditDetails] = useState<{
    relationship_id: string;
    patient_id: string;
    date_of_birth: string | undefined;
  }>({
    relationship_id: "",
    patient_id: "",
    date_of_birth: "",
  });

  const [search, setSearch] = useState("");

  // Further filter by search query if there's any

  const filteredAndSearchedDoctors =
    search !== ""
      ? doctorsData?.filter((doc) =>
          doc.full_name.toLowerCase().includes(search.toLowerCase())
        )
      : doctorsData;

  useEffect(() => {
    if (patient.contact_number.length === 10) {
      fetchRelations(undefined);
    }
  }, [patient.contact_number]);

  // Function to convert 12-hour time string to Date object
  function parseTimeCurrent(timeStr: string): Date {
    const [time, modifier] = timeStr.split(/(?<=\d)(?=[AP]M)/); // Split into time and AM/PM
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return new Date(0, 0, 0, hours, minutes);
  }

  // Function to convert 12-hour time string to Date object
  function parseTime(timeStr: string): Date {
    const [time, modifier] = timeStr.split(/(?<=\d)(?=[AP]M)/); // Split into time and AM/PM
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return new Date(0, 0, 0, hours + 2 > 24 ? 24 : hours + 2, minutes);
  }

  // Function to format current time as "hh:mmA"
  function formatCurrentTime(): string {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // Convert hour '0' to '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

    return `${hours}:${minutesStr}${ampm}`;
  }

  useEffect(() => {
    const currentTimeStr = formatCurrentTime();
    const timeToCompare = formState.docSession?.label.split("-")[1];
    if (
      formState.docSession.value !== "" &&
      currentTimeStr !== undefined &&
      timeToCompare !== undefined
    ) {
      const currentTime = parseTimeCurrent(currentTimeStr);
      const compareTime = parseTime(timeToCompare);

      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");

      if (
        currentTime > compareTime &&
        SelectedDate === `${year}-${month}-${day}`
      ) {
        setSessionExp(true);
      } else {
        setSessionExp(false);
      }
    }
    console.log(sessionExp);
  });

  const showSlotsTokens = useCallback(async () => {
    console.log("show");

    setFormState((prevState) => ({
      ...prevState,
      allTokens: [0],
      allSlots: [""],
    }));

    if (!mappingID || !formState.docSession) return;
    // console.log(session);
    const [start_time, end_time] = String(formState.docSession?.label)?.split(
      " - "
    );

    const data = {
      mapping_id: mappingID,
      booked_date: SelectedDate,
      number_of_people: 1,
      availability_id: formState.docSession.value,
      start_time: moment(start_time, "hh:mmA").format("HH:mm:ss"),
      end_time: moment(end_time, "hh:mmA").format("HH:mm:ss"),
    };

    if (queue_type !== "Token") {
      console.log(data);
      const slots_data = await getSlots(data);
      console.log(slots_data?.data);

      if (slots_data?.status === 200 && slots_data.data.result.length !== 0) {
        const api_data: { start: string; end: string }[] =
          slots_data.data.result;

        console.log("API Data:", api_data);

        const allSlots =
          SelectedDate === moment().format("YYYY-MM-DD")
            ? api_data
                .filter((slot) => moment(slot.end, "HH:mm").isAfter(moment()))
                .map((slot) => `${slot.start} - ${slot.end}`)
            : api_data.map((slot) => `${slot.start} - ${slot.end}`);

        // console.log("All Slots:", allSlots);

        const slicedData = allSlots.slice(
          formState.currentIndex,
          formState.currentIndex + 15
        );

        // console.log("Sliced Data:", slicedData);

        if (allSlots) {
          setFormState((prevState) => ({
            ...prevState,
            allSlots: allSlots.length === 0 ? [""] : allSlots,
            slicedSlots: allSlots.length === 0 ? [""] : slicedData,
          }));
          setBookingSlot(slicedData.slice(0, 1)[0]);
        }
      } else if (slots_data?.data.length === 0) {
        setFormState((prevState) => ({
          ...prevState,
          allSlots: [""],
        }));
      }
    }

    if (queue_type === "Token") {
      // console.log(data);
      const token_data = await getTokens(data);
      console.log(token_data?.data);

      if (token_data?.status === 200 && token_data.data.result.length !== 0) {
        const api_data: Array<number> = token_data.data.result;
        const allTokens =
          SelectedDate === moment().format("YYYY-MM-DD") &&
          moment().isAfter(
            moment(
              String(formState.docSession?.label).split(" - ")[1],
              "hh:mmA"
            ).add(30, "minutes")
          )
            ? [0]
            : mentsReception.includes(String(userData?.user_id))
            ? api_data.filter((token) => token % 2 !== 0)
            : api_data;
        // moment().isAfter(moment(String(session?.label).split(" - ")[1], "hh:mmA"))

        // console.log("allTokens", allTokens);

        const slicedData = allTokens.slice(
          formState.currentIndex,
          formState.currentIndex + 15
        );

        // console.log("slicedData", slicedData);

        if (allTokens.length !== 0) {
          setFormState((prevState) => ({
            ...prevState,
            allTokens: allTokens,
            slicedTokens: slicedData,
          }));
          setToken(slicedData.slice(0, 1)[0]);
          // console.log(slicedData.slice(0, formState.people)[0]);
        }
      } else if (token_data?.data.length === 0) {
        setFormState((prevState) => ({
          ...prevState,
          allTokens: [0],
        }));
      }
    }
  }, [mappingID, formState.docSession, SelectedDate, formState.currentIndex]);
  useEffect(() => {
    if (
      location.state !== null &&
      (undefined === doctor_id || undefined === mappingID)
    ) {
      setDoctor_id(location.state.doctor_id);
      doctorsData?.map((e) => {
        if (e.doctor_id === location.state.doctor_id) {
          setMappingID(e.mapping_id);
        }
      });
    } else if (doctor_id === undefined && doctorsData !== undefined) {
      setDoctor_id(doctorsData[0].doctor_id);
      setMappingID(doctorsData[0].mapping_id);
    }
  });

  useEffect(() => {
    showSlotsTokens();
  }, [mappingID, formState.docSession, showSlotsTokens]);

  const fetchDocAvailability = useCallback(async () => {
    setLoad(true);

    if (!mappingID) {
      setLoad(false);
      return;
    }

    const res = await getDoctorAvailability(mappingID);
    if (res?.status === 200) {
      const api_data: DocAvailability[] = res.data.result.doctor_availability;

      const data = api_data
        .filter((i) => i.day_of_week === formState.index)
        .map((item) => {
          return {
            value: String(item.availability_id),
            label: `${moment(item.start_time, "HH:mm:ss").format(
              "hh:mmA"
            )} - ${moment(item.end_time, "HH:mm:ss").format("hh:mmA")}`,

            data: {
              wait_time: item.wait_time.minutes,
              queue_type: item.queue_type,
            },
            start_time: item.start_time,
          };
        })
        .sort((a, b) => {
          // Sort by ascending order of time
          return moment(a.start_time, "HH:mm:ss").diff(
            moment(b.start_time, "HH:mm:ss")
          );
        });

      setLoad(false);

      const fetchExtension = async () => {
        console.log(data);
        let newOptions = data.slice();

        for (let i = 0; i < data.length; i++) {
          try {
            const res = await checkAvailability(data[i].value, SelectedDate);
            console.log("checkAvailability", res?.data.result);

            if (res?.status === 200 && res.data.result.length > 0) {
              const api_data: SessionAdjustment[] = res.data.result;

              api_data.forEach((apiResult) => {
                if (apiResult.type === "Unavailability") {
                  newOptions = newOptions.map((session) => {
                    if (apiResult.availability_id === session.value) {
                      return {
                        ...session,
                        label: "Unavailable Session",
                        data: {
                          ...session.data,
                        },
                      };
                    }
                    return session;
                  });
                } else if (apiResult.type === "Extension") {
                  newOptions = newOptions.map((session) => {
                    if (apiResult.availability_id === session.value) {
                      return {
                        ...session,
                        label: `${moment(
                          apiResult.session_start_time,
                          "HH:mm:ss"
                        ).format("hh:mmA")} - ${moment(
                          apiResult.extended_end_time,
                          "HH:mm:ss"
                        ).format("hh:mmA")}`,
                        data: {
                          ...session.data,
                        },
                      };
                    }
                    return session;
                  });
                }
              });
            } else if (res?.status === 404) {
              console.log(
                "Session ID " + data[i].value + " not found, no changes made."
              );
            } else {
              throw new Error("Unhandled response status: " + res.status);
            }
          } catch (error) {
            console.error("Error fetching session availability: ", error);
          }
        }

        setAllSessions(newOptions);

        const now = moment();
        // const now = moment().set({ hour: 16, minute: 0 });
        console.log(newOptions);
        const currSession = newOptions
          .filter((item) => {
            const startTime = moment(
              String(item?.label)?.split(" - ")[0],
              "HH:mmA"
            ).subtract(30, "minutes"); // Subtract 30 minutes from start time
            const endTime = moment(
              String(item?.label)?.split(" - ")[1],
              "HH:mmA"
            ).add(30, "minutes"); // Add 30 minutes to end time

            return now.isBetween(startTime, endTime); // Check if current time is within the modified range
          })
          .map((item) => {
            return {
              value: String(item.value),
              label: `${moment(
                String(item?.label)?.split(" - ")[0],
                "HH:mm:ss"
              ).format("hh:mmA")} - ${moment(
                String(item?.label)?.split(" - ")[1],
                "HH:mm:ss"
              ).format("hh:mmA")}`,
              data: {
                wait_time: item.data?.wait_time,
                queue_type: item.data?.queue_type,
              },
            };
          });

        if (currSession && currSession.length > 0) {
          const updatedSession = newOptions.find(
            (opt) => opt.value === currSession[0].value
          );
          setFormState((prevState) => ({
            ...prevState,
            docSession: updatedSession || newOptions[0],
          }));
          setQueue_type(
            updatedSession
              ? String(updatedSession?.data?.queue_type)
              : String(newOptions[0]?.data?.queue_type)
          );
        } else if (newOptions.length > 0) {
          setFormState((prevState) => ({
            ...prevState,
            docSession: newOptions[0],
          }));
          setQueue_type(String(newOptions[0]?.data?.queue_type));
        } else {
          console.log("No available sessions found");
          setAllSessions([]);
          // Optionally, handle the case when all sessions are unavailable or removed
        }
      };

      fetchExtension();
    } else {
      setFormState((prevState) => ({
        ...prevState,
        allSlots: [""],
        allTokens: [0],
      }));
      setLoad(false);
    }
  }, [doctorsData, mappingID, formState.index]);

  useEffect(() => {
    fetchDocAvailability();
  }, [SelectedDate, fetchDocAvailability]);

  const fetchRelations = async (e: any | undefined) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    if (
      (patient.contact_number.length === 12 &&
        patient.contact_number.substring(0, 2) === "91") ||
      (!isNaN(Number(patient.contact_number)) &&
        String(patient.contact_number).length === 10 &&
        /^[6-9]\d{9}$/.test(patient.contact_number))
    ) {
      var res;
      if (patient.contact_number.length === 12) {
        res = await getPatientByPhoneNo(patient.contact_number);
      } else {
        res = await getPatientByPhoneNo("91" + patient.contact_number);
      }
      if (res?.status === 200) {
        console.log("patient", res.data.result);
        const data = res.data.result[0];
        setPatient((prevState) => ({
          ...prevState,
          full_name: data.full_name?.trim(),
          contact_number: data.contact_number,
          gender: data.gender,
          patient_id: data.patient_id,
          registered: true,
        }));

        const api_data = await getPatientRelations(data.patient_id);
        if (api_data?.status === 200) {
          const relations = api_data.data.result;
          console.log("relations", relations);
          setRelations(relations);
        }
      } else {
        toast.error("Patient Not Found");
        setPatient((prevState) => ({
          ...prevState,
          full_name: "",
          gender: "",
          patient_id: "",
          registered: false,
        }));
        setRelations(undefined);
      }
    }
  };

  const resetStates = () => {
    setPatient({
      full_name: "",
      contact_number: "",
      gender: "",
      patient_id: "",
      registered: false,
    });
    setFormState({
      allSlots: [""],
      slicedSlots: [""],
      allTokens: [0],
      slicedTokens: [0],
      docSession: { value: "", label: "" },
      comments: "",
      type: "booking",
      currentIndex: 0,

      index: moment(SelectedDate).day() + 1,
    });
    setRelNumber("");
    setBookingFor("");
    setRelations([]);
    fetchDocAvailability();
  };

  const handleTokenBooking = async (patient_id: string) => {
    console.log("token");

    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      formState.docSession &&
      userData &&
      token
    ) {
      const availability_id = String(formState.docSession?.value);

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start: String(formState.docSession?.label)?.split(" - ")[0],
        booked_slot_end: String(formState.docSession?.label)?.split(" - ")[1],
        booking_session_start_time: String(formState.docSession?.label)?.split(
          " - "
        )[0],
        booking_session_end_time: String(formState.docSession?.label)?.split(
          " - "
        )[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospital_id,
        patient_id: patient_id,
        doctor_id: doctor_id,
        queue_type: queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
        token_number: token,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();

        toast.success(
          `${
            formState.type === "booking"
              ? "Appointment booked successfully!"
              : "In-clinic patient added!"
          }`
        );
      } else toast.error("Error occured while adding booking.");
    } else if (token === null) {
      toast.error("Please select a token.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSlotBooking = async (patient_id: string) => {
    console.log("slot");
    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      formState.docSession &&
      userData
    ) {
      const availability_id = String(formState.docSession?.value);

      const slot = bookingSlot;

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start:
          formState.type === "walk-in" ? null : slot?.split(" - ")[0] + ":00",
        booked_slot_end:
          formState.type === "walk-in" ? null : slot?.split(" - ")[1] + ":00",
        booking_session_start_time: String(formState.docSession?.label)?.split(
          " - "
        )[0],
        booking_session_end_time: String(formState.docSession?.label)?.split(
          " - "
        )[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospital_id,
        patient_id: patient_id,
        doctor_id: doctor_id,
        queue_type: queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();

        toast.success(
          `${
            formState.type === "booking"
              ? "Appointment booked successfully!"
              : "In-clinic patient added!"
          }`
        );
      } else toast.error("Error occured while adding booking.");
    } else if (bookingSlot === undefined && formState.type === "booking") {
      toast.error("Please select a slot.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleSessionBooking = async (patient_id: string) => {
    console.log("session");
    if (
      patient_id !== "" &&
      patient.full_name !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      formState.docSession &&
      userData
    ) {
      const availability_id = String(formState.docSession?.value);

      const req = {
        booking_mode: formState.type,
        booked_date: SelectedDate,
        booked_slot_start: String(formState.docSession?.label)?.split(" - ")[0],
        booked_slot_end: String(formState.docSession?.label)?.split(" - ")[1],
        booking_session_start_time: String(formState.docSession?.label)?.split(
          " - "
        )[0],
        booking_session_end_time: String(formState.docSession?.label)?.split(
          " - "
        )[1],
        is_confirmed: true,
        symptoms: "test",
        comments: formState.comments,
        availability_id: availability_id,
        hospital_id: hospital_id,
        patient_id: patient_id,
        doctor_id: doctor_id,
        queue_type: queue_type,
        status: formState.type === "walk-in" ? 1 : 0,
        added_by: userData.user_id,
      };

      const res = await addBooking(req);

      if (res?.status === 201) {
        resetStates();

        toast.success(
          `${
            formState.type === "booking"
              ? "Appointment booked successfully!"
              : "In-clinic patient added!"
          }`
        );
      } else toast.error("Error occured while adding booking.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handleBooking = async () => {
    if (
      patient.registered === true &&
      patient.full_name !== "" &&
      patient.contact_number !== "" &&
      SelectedDate &&
      formState.docSession
    ) {
      queue_type === "Token"
        ? handleTokenBooking(patient.patient_id)
        : queue_type === "Session"
        ? handleSessionBooking(patient.patient_id)
        : handleSlotBooking(patient.patient_id);
    } else if (
      patient.registered === false &&
      patient.full_name !== "" &&
      patient.contact_number !== "" &&
      patient.gender !== "" &&
      SelectedDate &&
      formState.docSession
    ) {
      //Create patient then make booking
      const req = {
        full_name: patient.full_name?.trim(),
        contact_number: "91" + patient.contact_number,
        gender: patient.gender,
        date_of_birth: moment().format("YYYY-MM-DD"),
      };
      const res = await createPatient(req);
      console.log(res.data.result);
      if (res?.status === 201) {
        setPatient((prevState) => ({
          ...prevState,
          registered: true,
        }));

        queue_type === "Token"
          ? handleTokenBooking(res.data.result.patient_id)
          : queue_type === "Session"
          ? handleSessionBooking(res.data.result.patient_id)
          : handleSlotBooking(res.data.result.patient_id);
      }
    } else if (patient.full_name === "") {
      toast.error("Please enter patient name.");
    } else if (patient.gender === "") {
      toast.error("Please enter patient gender.");
    } else if (patient.contact_number === "") {
      toast.error("Please enter patient phone number.");
    } else {
      toast.error("Invalid inputs.");
    }
  };

  const handlePatientCheck = async (e: any) => {
    setRelationToAdd((prevState) => ({
      ...prevState,
      contact_number: e.target.value,
    }));
    if (
      !isNaN(Number(e.target.value)) &&
      String(e.target.value).length === 10 &&
      /^[6-9]\d{9}$/.test(e.target.value)
    ) {
      console.log(e.target.value);
      const resp = await getPatientByPhoneNo("91" + e.target.value);

      if (resp?.status === 200) {
        console.log(resp);

        setRelationToAdd((prevState) => ({
          ...prevState,
          full_name: resp.data.result[0].full_name,
        }));
        toast.success("Patient Found");
      }
    }
  };
  const handleEditRelation = async () => {
    if (type === "") {
      toast.error("Select Relation Type");
      return;
    }
    const res = await updateRelation(editDeatils.relationship_id, type);

    const req = {
      full_name: relationToAdd.full_name,
      contact_number: "91" + relationToAdd.contact_number || null,
      gender: relationToAdd.gender,
      date_of_birth: editDeatils.date_of_birth || moment().format("YYYY-MM-DD"),
    };

    const api_data = await updatePatient(editDeatils.patient_id, req);

    if (res?.status === 200 || api_data?.status === 200) {
      toast.success("Updated successfully!");
      fetchRelations(undefined);
      setEditRel(false);
      setRelModal(true);
    }
  };
  const handleAddRelation = async () => {
    if (relationToAdd && relationToAdd.full_name !== "" && type !== "") {
      var patientId;
      if (!patient.registered) {
        const req = {
          full_name: patient.full_name?.trim(),
          contact_number: "91" + patient.contact_number,
          gender: patient.gender,
          date_of_birth: moment().format("YYYY-MM-DD"),
        };
        const res = await createPatient(req);

        if (res?.status === 201) {
          patientId = res.data.result.patient_id;
          setPatient((prevState) => ({
            ...prevState,
            patient_id: res.data.result.patient_id,
            registered: true,
          }));
        }
      }
      const req1 = {
        full_name: relationToAdd.full_name,
        gender: relationToAdd.gender,
        date_of_birth: moment().format("YYYY-MM-DD"),
        manager_contact_number: patient.contact_number,
      };
      const req2 = {
        full_name: relationToAdd.full_name,
        contact_number: "91" + relationToAdd.contact_number,
        gender: relationToAdd.gender,
        date_of_birth: moment().format("YYYY-MM-DD"),
      };
      console.log("91" + (relationToAdd.contact_number === ""
          ? patient.contact_number
          : relationToAdd.contact_number));
      

      const resp = await getPatientByPhoneNo(
        "91" + relationToAdd.contact_number
      );

      if (resp?.status === 200 && relationToAdd.contact_number!=="") {
        console.log("patient", resp.data.result);
        const data = resp.data.result[0];
        const api_data = await addRelation(
          patient.patient_id,
          data.patient_id,
          type
        );
        if (api_data?.status === 200) {
          console.log(api_data.data.result);
          toast.success("Relation added successfully!");
          fetchRelations(undefined);
          setRelationStep("");
          setRelationToAdd({
            full_name: "",
            contact_number: "",
            gender: "",
          });
          setType("");
        } else toast.error("Relation already exists with same number");
      } else {
        const res = await createPatient(
          relationToAdd.contact_number === "" ? req1 : req2
        );
        if (res?.status === 201) {
          const data = res.data.result;

          const api_data = await addRelation(
            patient.patient_id || patientId,
            data.patient_id,
            type
          );
          if (api_data?.status === 200) {
            console.log(api_data.data.result);
            toast.success("Relation added successfully!");
            fetchRelations(undefined);
            setRelationToAdd({
              full_name: "",
              contact_number: "",
              gender: "",
            });
            setType("");
            setRelationStep("");
          } else toast.error("Error occured while adding relation.");
        } else toast.error("Error occured while adding relation.");
      }
    } else if (relationToAdd.full_name === "") {
      toast.error("Name is required.");
    } else if (type === "") {
      toast.error("Please select a relationship type.");
    }
    setAddRel(false);
    setRelModal(true);
  };

  return (
    <>
      <div className="flex flex-col w-full pb-10">
        <Header header value={"Appointment Booking"} />

        <div className="flex justify-between px-4 lg:px-10 flex-wrap">
          <div className="flex flex-col mt-6 w-full md:w-[49%] bg-white rounded-lg border-[1px] border-doctorsBorder p-5">
            <h1 className="text-[#8A8A8A] font-semibold text-xl">
              Patient Information
            </h1>
            <div>
              <p className="text-modalText text-md mb-1 mt-12 font-bold">
                Patient's Phone Number{" "}
                <span className="text-red-500 text-sm relative bottom-1 font-medium">
                  *
                </span>
              </p>
              <form onSubmit={fetchRelations}>
                <div className="flex gap-4">
                  <label className="absolute mt-3 ml-2 text-black">+91 </label>
                  <input
                    className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full pl-12"
                    placeholder="Enter phone number"
                    value={
                      patient.contact_number.length === 12 &&
                      patient.contact_number.substring(0, 2) === "91"
                        ? patient.contact_number.substring(2)
                        : patient.contact_number
                    }
                    onChange={(e) =>
                      setPatient((prevState) => ({
                        ...prevState,
                        contact_number: e.target.value,
                      }))
                    }
                    minLength={10}
                    maxLength={10}
                    required
                    autoFocus
                  />
                  {/* <DarkBlueButton
                    type="submit"
                    name="Verify"
                    className="w-1/4 !bg-queueHover !opacity-100"
                  /> */}
                </div>
              </form>
            </div>
            <div>
              <p className="text-modalText text-md mb-1 mt-8 font-bold">
                Full Name{" "}
                <span className="text-red-500 text-sm relative bottom-1 font-medium">
                  *
                </span>
              </p>
              <input
                className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full"
                placeholder="Your Name"
                value={patient.full_name}
                onChange={(e) =>
                  setPatient((prevState) => ({
                    ...prevState,
                    full_name: e.target.value,
                  }))
                }
                required
              />
            </div>
            <div>
              <p className="text-modalText text-md mb-1 mt-8 font-bold">
                Booking for
              </p>
              <input
                className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full"
                placeholder="Your Name"
                value={
                  bookingFor === undefined || bookingFor === "Self"
                    ? patient.full_name
                    : bookingFor
                }
                onChange={(e) => {
                  setBookingFor(e.target.value);
                }}
                disabled
              />
            </div>
            <div>
              <p className="text-modalText text-md mb-1 mt-12 font-bold">
                Relations's Phone Number
              </p>
              <form>
                <div className="flex gap-4">
                  <label className="absolute mt-3 ml-2 text-black">+91 </label>
                  <input
                    className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full pl-12"
                    placeholder="Enter phone number"
                    value={
                      (relNumber !== "" &&
                      relNumber.length === 12 &&
                      relNumber.substring(0, 2) === "91"
                        ? relNumber.substring(2)
                        : relNumber) ||
                      (relNumber === "" &&
                      patient.contact_number.length === 12 &&
                      patient.contact_number.substring(0, 2) === "91"
                        ? patient.contact_number.substring(2)
                        : patient.contact_number)
                    }
                    disabled
                  />
                </div>
              </form>
            </div>
            <div>
              <div className="flex gap-4 items-center">
                <Modal
                  isOpen={relModal}
                  onRequestClose={() => {
                    setRelModal(false);
                  }}
                  style={modalStyles}
                  ariaHideApp={false}
                >
                  {/* Header */}
                  <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
                    <p className="text-dark text-md font-semibold">
                      Manage Relation
                    </p>

                    <button
                      className="btn bg-black text-white rounded-md font-semibold p-8 py-2"
                      onClick={() => {
                        setRelModal(false);
                        setAddRel(true);
                      }}
                    >
                      Add Relation
                    </button>
                  </div>

                  {/* Body */}
                  <form className="mb-0">
                    <div className="p-0 max-h-96 overflow-y-auto">
                      <div className="px-6 py-5 bg-lightGrey">
                        {relations === undefined && (
                          <p className="mt-2">No relation found</p>
                        )}
                        {relations !== undefined && (
                          <div className="mt-4">
                            {relations?.map((data) => (
                              <div
                                className={
                                  bookingFor === data.full_name
                                    ? "p-2 px-4 mt-4 rounded-lg border-[1px]  flex flex-col justify-center items-center cursor-pointer border-queueHover"
                                    : "p-2 px-4 mt-4 rounded-lg border-[1px] border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:border-queueHover"
                                }
                                onClick={() => {
                                  setBookingFor(data.full_name);
                                }}
                              >
                                <div className="flex justify-between w-full items-center">
                                  <div>
                                    {" "}
                                    <p className="font-semibold">
                                      {data.relationship_type}
                                    </p>
                                    <p className="text-sm">{data.full_name}</p>
                                  </div>
                                  <div className="flex gap-2">
                                    <MdDelete
                                      className="w-6 h-6 text-red-600 relative top-0 transition-all hover:top-[-3px]"
                                      onClick={async () => {
                                        if (relations.length > 2) {
                                          const api_data = await deleteRelation(
                                            data.relationship_id
                                          );
                                          if (api_data?.status === 200) {
                                            toast.success(
                                              "Relation deleted successfully!"
                                            );
                                            fetchRelations(undefined);
                                            setBookingFor(undefined);
                                          } else
                                            toast.error(
                                              "Error occured while deleting relation."
                                            );
                                        } else if (relations.length === 2) {
                                          const api_data = await deleteRelation(
                                            data.relationship_id
                                          );
                                          if (api_data?.status === 200) {
                                            toast.success(
                                              "Relation deleted successfully!"
                                            );
                                            setRelModal(false);
                                            setRelations(undefined);
                                            fetchRelations(undefined);
                                            setBookingFor(undefined);
                                          } else
                                            toast.error(
                                              "Error occured while deleting relation."
                                            );
                                        }
                                      }}
                                    />
                                    <RiEdit2Fill
                                      className="w-6 h-6 relative top-0 transition-all hover:top-[-3px]"
                                      onClick={async () => {
                                        setEditRel(true);
                                        setRelModal(false);
                                        setEditDetails((prev) => ({
                                          ...prev,
                                          relationship_id: data.relationship_id,
                                          patient_id: data.patient_id,
                                          date_of_birth: data.date_of_birth,
                                        }));
                                        setRelationToAdd((prevState) => ({
                                          ...prevState,
                                          contact_number: String(
                                            data.contact_number?.substring(2)
                                          ),
                                          full_name: String(data.full_name),
                                          gender: String(data.gender),
                                        }));
                                        setType(data.relationship_type);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Footer */}
                    <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
                      <WhiteButton
                        name="Cancel & Go Back"
                        className="w-full h-14"
                        handleClick={() => {
                          setRelModal(false);
                          setRelationToAdd({
                            full_name: "",
                            contact_number: "",
                            gender: "",
                          });
                        }}
                      />
                      <DarkBlueButton
                        type="button"
                        name="Update"
                        className="w-full h-14"
                        handleClick={() => {
                          setRelModal(false);
                        }}
                      />
                    </div>
                  </form>
                </Modal>
                <Modal
                  isOpen={editRel}
                  onRequestClose={() => {
                    setEditRel(false);
                  }}
                  style={modalStyles}
                  ariaHideApp={false}
                >
                  {/* Header */}
                  <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
                    <p className="text-dark text-md font-semibold">
                      Edit Relation
                    </p>
                    <CloseButton
                      handleClick={() => {
                        setEditRel(false);
                        setRelModal(true);
                        setRelationToAdd({
                          full_name: "",
                          contact_number: "",
                          gender: "",
                        });
                        setType("");
                      }}
                    />
                  </div>

                  {/* Body */}
                  <form className="mb-0">
                    <div className="p-0 max-h-96 overflow-y-auto">
                      <div className="px-6 py-5 bg-lightGrey">
                        <div className="flex flex-col w-full">
                          <p className="text-modalText text-md mb-1 font-bold">
                            Full Name <span className="text-red-500">*</span>
                          </p>
                          <input
                            className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full mt-2"
                            placeholder="Enter full name"
                            type="text"
                            autoFocus
                            required
                            value={relationToAdd.full_name}
                            onChange={(e) =>
                              setRelationToAdd((prevState) => ({
                                ...prevState,
                                full_name: e.target.value,
                              }))
                            }
                          />
                        </div>

                        <div className="flex flex-row space-x-6 mt-2">
                          <div className="flex flex-col w-[50%] mt-4">
                            <p className="text-modalText text-md mb-1 font-bold">
                              Relationship Type
                              <span className="text-red-500">*</span>
                            </p>
                            <select
                              name="type"
                              id="type"
                              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full h-12 mt-2"
                              value={type}
                              onChange={(e) => {
                                setType(e.target.value);
                                if (
                                  e.target.value === "Father" ||
                                  e.target.value === "Brother" ||
                                  e.target.value === "Husband" ||
                                  e.target.value === "Son"
                                ) {
                                  setRelationToAdd((prevState) => ({
                                    ...prevState,
                                    gender: "Male",
                                  }));
                                } else if (e.target.value === "Other") {
                                  setRelationToAdd((prevState) => ({
                                    ...prevState,
                                    gender: "Male",
                                  }));
                                } else {
                                  setRelationToAdd((prevState) => ({
                                    ...prevState,
                                    gender: "Female",
                                  }));
                                }
                              }}
                            >
                              <option value="">Select Relation</option>
                              <option value="Father">Father</option>
                              <option value="Mother">Mother</option>
                              <option value="Brother">Brother</option>
                              <option value="Sister">Sister</option>
                              <option value="Husband">Husband</option>
                              <option value="Wife">Wife</option>
                              <option value="Son">Son</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                          <div className="mt-4 w-[50%]">
                            <p className="text-modalText text-md mb-1 font-bold">
                              Phone Number
                            </p>
                            <input
                              className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full h-12 mt-2"
                              placeholder="Enter phone number"
                              value={relationToAdd.contact_number}
                              onChange={handlePatientCheck}
                              minLength={10}
                              maxLength={10}
                            />
                          </div>
                        </div>
                        {type === "Other" && (
                          <div className="flex  flex-wrap gap-4 mt-6">
                            <div
                              className={
                                relationToAdd.gender === "Male"
                                  ? "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-[#C3D2FF]  text-[#335FE4] w-32"
                                  : "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-[#C3D2FF]  hover:text-[#335FE4] w-32"
                              }
                              onClick={() =>
                                setRelationToAdd((prevState) => ({
                                  ...prevState,
                                  gender: "Male",
                                }))
                              }
                            >
                              <p className="font-normal">Male</p>
                            </div>
                            <div
                              className={
                                relationToAdd.gender === "Female"
                                  ? "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-[#C3D2FF]  text-[#335FE4] w-32"
                                  : "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-[#C3D2FF]  hover:text-[#335FE4] w-32"
                              }
                              onClick={() =>
                                setRelationToAdd((prevState) => ({
                                  ...prevState,
                                  gender: "Female",
                                }))
                              }
                            >
                              <p className="font-normal">Female</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Footer */}
                    <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
                      <WhiteButton
                        name="Cancel & Go Back"
                        className="w-full h-14"
                        handleClick={() => {
                          setEditRel(false);
                          setRelModal(true);
                          setRelationToAdd({
                            full_name: "",
                            contact_number: "",
                            gender: "",
                          });
                          setType("");
                        }}
                      />
                      <DarkBlueButton
                        type="button"
                        name="Edit Relation"
                        className="w-full h-14"
                        handleClick={handleEditRelation}
                      />
                    </div>
                  </form>
                </Modal>
                <Modal
                  isOpen={addRel}
                  onRequestClose={() => {
                    setAddRel(false);
                  }}
                  style={modalStyles}
                  ariaHideApp={false}
                >
                  {/* Header */}
                  <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
                    <p className="text-dark text-md font-semibold">
                      Add Relation
                    </p>
                    <CloseButton
                      handleClick={() => {
                        setAddRel(false);
                        setRelModal(true);
                        setRelationToAdd({
                          full_name: "",
                          contact_number: "",
                          gender: "",
                        });
                        setType("");
                      }}
                    />
                  </div>

                  {/* Body */}
                  <form className="mb-0">
                    <div className="p-0 max-h-96 overflow-y-auto">
                      <div className="px-6 py-5 bg-lightGrey">
                        <div className="flex flex-col w-full">
                          <p className="text-modalText text-md mb-1 font-bold">
                            Full Name <span className="text-red-500">*</span>
                          </p>
                          <input
                            className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full mt-2"
                            placeholder="Enter full name"
                            type="text"
                            autoFocus
                            required
                            value={relationToAdd.full_name}
                            onChange={(e) =>
                              setRelationToAdd((prevState) => ({
                                ...prevState,
                                full_name: e.target.value,
                              }))
                            }
                          />
                        </div>

                        <div className="flex flex-row space-x-6 mt-2">
                          <div className="flex flex-col w-[50%] mt-4">
                            <p className="text-modalText text-md mb-1 font-bold">
                              Relationship Type
                              <span className="text-red-500">*</span>
                            </p>
                            <select
                              name="type"
                              id="type"
                              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full h-12 mt-2"
                              value={type}
                              onChange={(e) => {
                                setType(e.target.value);
                                if (
                                  e.target.value === "Father" ||
                                  e.target.value === "Brother" ||
                                  e.target.value === "Husband" ||
                                  e.target.value === "Son"
                                ) {
                                  setRelationToAdd((prevState) => ({
                                    ...prevState,
                                    gender: "Male",
                                  }));
                                } else if (e.target.value === "Other") {
                                  setRelationToAdd((prevState) => ({
                                    ...prevState,
                                    gender: "Male",
                                  }));
                                } else {
                                  setRelationToAdd((prevState) => ({
                                    ...prevState,
                                    gender: "Female",
                                  }));
                                }
                              }}
                            >
                              <option value="">Select Relation</option>
                              <option value="Father">Father</option>
                              <option value="Mother">Mother</option>
                              <option value="Brother">Brother</option>
                              <option value="Sister">Sister</option>
                              <option value="Husband">Husband</option>
                              <option value="Wife">Wife</option>
                              <option value="Son">Son</option>
                              <option value="Daughter">Daughter</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                          <div className="mt-4 w-[50%]">
                            <p className="text-modalText text-md mb-1 font-bold">
                              Phone Number
                            </p>
                            <input
                              className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full h-12 mt-2"
                              placeholder="Enter phone number"
                              value={relationToAdd.contact_number}
                              onChange={handlePatientCheck}
                              minLength={10}
                              maxLength={10}
                            />
                          </div>
                        </div>
                        {type === "Other" && (
                          <div className="flex  flex-wrap gap-4 mt-6">
                            <div
                              className={
                                relationToAdd.gender === "Male"
                                  ? "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-[#C3D2FF]  text-[#335FE4] w-32"
                                  : "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-[#C3D2FF]  hover:text-[#335FE4] w-32"
                              }
                              onClick={() =>
                                setRelationToAdd((prevState) => ({
                                  ...prevState,
                                  gender: "Male",
                                }))
                              }
                            >
                              <p className="font-normal">Male</p>
                            </div>
                            <div
                              className={
                                relationToAdd.gender === "Female"
                                  ? "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-[#C3D2FF]  text-[#335FE4] w-32"
                                  : "p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-[#C3D2FF]  hover:text-[#335FE4] w-32"
                              }
                              onClick={() =>
                                setRelationToAdd((prevState) => ({
                                  ...prevState,
                                  gender: "Female",
                                }))
                              }
                            >
                              <p className="font-normal">Female</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Footer */}
                    <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
                      <WhiteButton
                        name="Cancel & Go Back"
                        className="w-full h-14"
                        handleClick={() => {
                          setAddRel(false);
                          setRelModal(true);
                          setRelationToAdd({
                            full_name: "",
                            contact_number: "",
                            gender: "",
                          });
                          setType("");
                        }}
                      />
                      <DarkBlueButton
                        type="button"
                        name="Add Relation"
                        className="w-full h-14"
                        handleClick={async () => {
                          handleAddRelation();
                        }}
                      />
                    </div>
                  </form>
                </Modal>
                <p className="text-modalText text-md mb-1 mt-8 font-bold">
                  Relation
                </p>
                <p
                  className="text-blue-500 text-sm font-semibold mb-1 mt-8 cursor-pointer"
                  onClick={() => {
                    if (patient.full_name && patient.contact_number) {
                      setRelModal(true);
                    } else {
                      toast.error("Enter Full Name / Phone Number");
                    }
                  }}
                >
                  Manage / Add relation
                </p>
              </div>
              {relations === undefined && (
                <p className="mt-2">No relation found</p>
              )}
              {relations !== undefined && (
                <div className="flex  flex-wrap gap-4 mt-4">
                  {relations?.map((data) => (
                    <div
                      className={
                        bookingFor === data.full_name ||
                        (bookingFor === undefined &&
                          data.relationship_type === "Self")
                          ? "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-queueHover text-white min-w-32"
                          : "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-queueHover  hover:text-white min-w-32"
                      }
                      onClick={() => {
                        setBookingFor(data.full_name);
                        setPatient({
                          ...patient,
                          patient_id: data.patient_id,
                        });

                        if (data.contact_number === null) {
                          setRelNumber(patient.contact_number);
                        } else {
                          setRelNumber(String(data.contact_number));
                        }
                      }}
                    >
                      <p className="font-semibold">{data.relationship_type}</p>
                      <p className="text-sm">{data.full_name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div>
              <p className="text-modalText text-md mb-1 mt-8 font-bold">
                Patient’s Gender
              </p>

              <div className="flex  flex-wrap gap-4 mt-4">
                <div
                  className={
                    patient.gender === "Male"
                      ? "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-queueHover  text-white w-32"
                      : "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-queueHover  hover:text-white w-32"
                  }
                  onClick={() =>
                    setPatient((prevState) => ({
                      ...prevState,
                      gender: "Male",
                    }))
                  }
                >
                  <p className="font-semibold">Male</p>
                </div>
                <div
                  className={
                    patient.gender === "Female"
                      ? "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-queueHover  text-white w-32"
                      : "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-queueHover  hover:text-white w-32"
                  }
                  onClick={() =>
                    setPatient((prevState) => ({
                      ...prevState,
                      gender: "Female",
                    }))
                  }
                >
                  <p className="font-semibold">Female</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-6 w-full md:w-[49%] bg-white rounded-lg border-[1px] border-doctorsBorder p-5">
            <h1 className="text-[#8A8A8A] font-semibold text-xl">
              Appointment Details
            </h1>
            <div>
              <p className="text-modalText text-md mb-1 mt-8 font-bold">
                Booking Type{" "}
                <span className="text-red-500 text-sm relative bottom-1 font-medium">
                  *
                </span>
              </p>

              <div className="flex  flex-wrap gap-4 mt-4">
                <div
                  className={
                    formState.type === "booking"
                      ? "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-queueHover  text-white w-32"
                      : "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-queueHover  hover:text-white w-32"
                  }
                  onClick={() =>
                    setFormState((prevState) => ({
                      ...prevState,
                      type: "booking",
                    }))
                  }
                >
                  <p className="font-semibold">Booking</p>
                </div>
                <div
                  className={
                    formState.type === "walk-in"
                      ? "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-queueHover  text-white w-32"
                      : "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-queueHover  hover:text-white w-32"
                  }
                  onClick={() =>
                    setFormState((prevState) => ({
                      ...prevState,
                      type: "walk-in",
                    }))
                  }
                >
                  <p className="font-semibold">Walk-In</p>
                </div>
                {/* <div
                  className={
                    formState.type === "follow-up"
                      ? "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-queueHover  text-white w-32"
                      : "p-4 py-3 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer hover:bg-queueHover  hover:text-white w-32"
                  }
                  onClick={() =>
                    setFormState((prevState) => ({
                      ...prevState,
                      type: "follow-up",
                    }))
                  }
                >
                  <p className="font-semibold">Follow Up</p>
                </div> */}
              </div>
            </div>
            <div>
              <p className="text-modalText text-md mb-1 mt-12 font-bold">
                Choose Doctor{" "}
                <span className="text-red-500 text-sm relative bottom-1 font-medium">
                  *
                </span>
              </p>
              <Modal
                isOpen={docModal}
                onRequestClose={() => {
                  setDocModal(false);
                }}
                style={modalStyles}
                ariaHideApp={false}
              >
                {/* Header */}
                <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
                  <p className="text-dark text-md font-bold">Choose Doctor</p>
                  <CloseButton
                    handleClick={() => {
                      setDocModal(false);
                    }}
                  />
                </div>

                {/* Body */}
                <form className="mb-0">
                  <div className="p-0 max-h-96 overflow-y-auto">
                    <div className="px-6 py-5 bg-lightGrey">
                      <div className="flex items-center text-sm m-3 md:m-0">
                        <div className="flex items-center rounded-lg border-[0.5px] border-sbBorder w-full bg-white">
                          <input
                            className="border-0 p-0 px-4 py-2 border-sbBorder opacity-80 rounded-lg focus:outline-none w-full text-lg"
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <p className="w-10">
                            <Search />
                          </p>
                        </div>
                      </div>
                      {filteredAndSearchedDoctors
                        ?.sort(
                          (a, b) =>
                            moment(a.created_at).valueOf() -
                            moment(b.created_at).valueOf()
                        )
                        ?.map((doctor) => (
                          <>
                            {doctor.doctor_id === doctor_id ? (
                              <div
                                className="flex gap-4 items-center mt-6 bg-queueHover text-white rounded-md p-2 cursor-pointer"
                                onClick={() => {
                                  setDoctor_id(doctor.doctor_id);
                                  setMappingID(doctor.mapping_id);
                                }}
                              >
                                {doctor?.profile_picture ===
                                  doctor?.full_name ||
                                doctor?.profile_picture === "pfp" ||
                                isFirebaseStorageUrl(
                                  doctor?.profile_picture
                                ) ? (
                                  <ProfilePicture
                                    username={
                                      doctor?.full_name ? doctor?.full_name : ""
                                    }
                                    className={"mx-2"}
                                  />
                                ) : (
                                  <img
                                    className={`w-10 h-10 rounded-full mx-2`}
                                    src={doctor?.profile_picture}
                                    alt="doctor"
                                  ></img>
                                )}
                                <div>
                                  <p className="text-md font-semibold">
                                    {doctor.full_name}
                                  </p>
                                  <p className="text-sm">
                                    {doctor.specialization}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="flex gap-4 items-center mt-6 hover:bg-queueHover hover:text-white rounded-md p-2 cursor-pointer"
                                onClick={() => {
                                  setDoctor_id(doctor.doctor_id);
                                  setMappingID(doctor.mapping_id);
                                }}
                              >
                                {doctor?.profile_picture ===
                                  doctor?.full_name ||
                                doctor?.profile_picture === "pfp" ||
                                isFirebaseStorageUrl(
                                  doctor?.profile_picture
                                ) ? (
                                  <ProfilePicture
                                    username={
                                      doctor?.full_name ? doctor?.full_name : ""
                                    }
                                    className={"mx-2"}
                                  />
                                ) : (
                                  <img
                                    className={`w-10 h-10 rounded-full mx-2`}
                                    src={doctor?.profile_picture}
                                    alt="doctor"
                                  ></img>
                                )}
                                <div>
                                  <p className="text-md font-semibold">
                                    {doctor.full_name}
                                  </p>
                                  <p className="text-sm">
                                    {doctor.specialization}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      {filteredAndSearchedDoctors?.length === 0 && (
                        <div className="flex gap-4 items-center mt-8 justify-center">
                          <div>
                            <p className="text-md">No doctor found</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Footer */}
                  <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
                    <WhiteButton
                      name="Cancel"
                      className="w-full h-14"
                      handleClick={() => {
                        setDocModal(false);
                      }}
                    />
                    <DarkBlueButton
                      type="button"
                      name="Select Doctor"
                      className="w-full h-14"
                      handleClick={() => {
                        setDocModal(false);
                      }}
                    />
                  </div>
                </form>
              </Modal>
              <div
                className="flex rounded-lg px-3 py-4 border-[0.5px] border-sbBorder w-full justify-between items-center cursor-pointer"
                onClick={() => {
                  setDocModal(true);
                }}
              >
                <div className="flex gap-4 items-center">
                  {doctorsData?.map((doctor) => (
                    <>
                      {doctor.doctor_id === doctor_id && (
                        <>
                          {doctor?.profile_picture === doctor?.full_name ||
                          doctor?.profile_picture === "pfp" ||
                          isFirebaseStorageUrl(doctor?.profile_picture) ? (
                            <ProfilePicture
                              username={
                                doctor?.full_name ? doctor?.full_name : ""
                              }
                              className={"mx-2"}
                            />
                          ) : (
                            <img
                              className={`w-10 h-10 rounded-full mx-2 hidden md:flex`}
                              src={doctor?.profile_picture}
                              alt="doctor"
                            ></img>
                          )}
                          <p>{doctor.full_name}</p>
                        </>
                      )}
                    </>
                  ))}
                  {undefined === doctor_id && doctorsData !== undefined && (
                    <>
                      {doctorsData[0]?.profile_picture ===
                        doctorsData[0]?.full_name ||
                      doctorsData[0]?.profile_picture === "pfp" ||
                      isFirebaseStorageUrl(doctorsData[0]?.profile_picture) ? (
                        <ProfilePicture
                          username={
                            doctorsData[0]?.full_name
                              ? doctorsData[0]?.full_name
                              : ""
                          }
                          className={"mx-2"}
                        />
                      ) : (
                        <img
                          className={`w-10 h-10 rounded-full mx-2 hidden md:flex`}
                          src={doctorsData[0]?.profile_picture}
                          alt="doctor"
                        ></img>
                      )}
                      <p>{doctorsData[0].full_name}</p>
                    </>
                  )}
                </div>
                <RightArrow />
              </div>
            </div>
            <div>
              <p className="text-modalText text-md mb-1 mt-8 font-bold">
                Comments{" "}
                <span className="text-red-500 text-sm relative bottom-1 font-medium">
                  *
                </span>
              </p>
              <textarea
                className="rounded-lg p-3 border-[0.5px] border-sbBorder w-full min-h-32 max-h-32"
                placeholder="Your Comments"
                value={formState.comments}
                onChange={(e) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    comments: e.target.value,
                  }))
                }
                required
              />
            </div>
            <div className="mt-3">
              <p className="text-modalText text-md mb-1 mt-8 font-bold">
                Booking For{" "}
                <span className="text-red-500 text-sm relative bottom-1 font-medium">
                  *
                </span>
              </p>
              <div className="flex flex-row">
                <input
                  className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full mr-5"
                  placeholder="Select Date"
                  type="date"
                  value={SelectedDate}
                  min={moment().format("YYYY-MM-DD")}
                  max={moment().add(2, "weeks").format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const selectedDate = e.target.value;
                    const dayOfWeek = moment(selectedDate).day() + 1;
                    setIndex(dayOfWeek);
                    setFormState({
                      ...formState,
                      index: moment(e.target.value).day() + 1,
                    });
                    setSelectedDate(e.target.value);
                  }}
                />
                {allSessions &&
                allSessions.length > 0 &&
                allSessions.filter(
                  (data) => data.label !== "Unavailable Session"
                ).length > 0 ? (
                  <select
                    value={formState.docSession?.value}
                    className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                    onChange={(e) => {
                      setFormState((prevState) => ({
                        ...prevState,
                        allSlots: [""],
                        allTokens: [0],
                      }));
                      const label =
                        e.target.options[e.target.selectedIndex].text;
                      const value = e.target.value;
                      setFormState((prevState) => ({
                        ...prevState,
                        docSession: { label, value },
                      }));
                      console.log(allSessions);

                      console.log(
                        "queue_type",
                        allSessions?.filter(
                          (session) => session.value === value
                        )[0].data?.queue_type
                      );
                      console.log(allSessions);

                      setQueue_type(
                        String(
                          allSessions?.filter(
                            (session) => session.value === value
                          )[0].data?.queue_type
                        )
                      );
                    }}
                  >
                    <option disabled hidden>
                      {String(formState.docSession?.label).split(" - ")[0] +
                        " - " +
                        String(formState.docSession?.label).split(" - ")[1]}
                    </option>
                    {allSessions.map((session) => (
                      <>
                        {session.label !== "Unavailable Session" && (
                          <option key={session.value} value={session.value}>
                            {String(session?.label).split(" - ")[0] +
                              " - " +
                              String(session?.label).split(" - ")[1]}
                          </option>
                        )}
                      </>
                    ))}
                  </select>
                ) : (
                  <NotFound text="No sessions" />
                )}
              </div>
            </div>
            <Modal
              isOpen={slotModal}
              onRequestClose={() => {
                setSlotModal(false);
              }}
              style={modalStyles}
              ariaHideApp={false}
            >
              {/* Header */}
              <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
                <p className="text-dark text-md font-bold">
                  Choose slot timing
                </p>
                <CloseButton
                  handleClick={() => {
                    setSlotModal(false);
                  }}
                />
              </div>

              {/* Body */}
              <form className="mb-0">
                <div className="p-0 max-h-96 overflow-y-auto">
                  <div className="px-6 py-5 bg-lightGrey">
                    {queue_type !== "Session" &&
                    noExtendSession.includes(hospital_id) ? (
                      formState.type !== "walk-in" && (
                        <div className="flex flex-col w-full my-3 mt-0">
                          <div>
                            <div className="flex flex-wrap justify-start gap-3 my-3 mt-0">
                              {queue_type === "Token" ? (
                                formState.allTokens.length !== 0 &&
                                formState.allTokens[0] !== 0 ? (
                                  formState.allTokens.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <SelectToken
                                        selected={token === item}
                                        setToken={setToken}
                                        item={item}
                                      />
                                    </React.Fragment>
                                  ))
                                ) : (
                                  <NotFound text="No Tokens Available" />
                                )
                              ) : formState.allSlots.length !== 1 ||
                                formState.allSlots[0] !== "" ? (
                                formState.allSlots.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <SelectSlot
                                      item={item}
                                      setSlot={setBookingSlot}
                                      selected={
                                        bookingSlot?.includes(item) || false
                                      }
                                      people={1}
                                    />
                                  </React.Fragment>
                                ))
                              ) : (
                                <NotFound text="No Slots Available" />
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="flex flex-col w-full my-3 mt-0">
                        <div>
                          <div className="flex flex-wrap justify-start my-3 gap-3 mt-0">
                            {queue_type === "Token" ? (
                              formState.allTokens.length !== 0 &&
                              formState.allTokens[0] !== 0 ? (
                                formState.allTokens.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <SelectToken
                                      selected={token === item}
                                      setToken={setToken}
                                      item={item}
                                    />
                                  </React.Fragment>
                                ))
                              ) : (
                                <NotFound text="No Tokens Available" />
                              )
                            ) : formState.allSlots.length !== 1 ||
                              formState.allSlots[0] !== "" ? (
                              formState.allSlots.map((item, index) => (
                                <React.Fragment key={index}>
                                  <SelectSlot
                                    item={item}
                                    setSlot={setBookingSlot}
                                    selected={
                                      bookingSlot?.includes(item) || false
                                    }
                                    people={1}
                                  />
                                </React.Fragment>
                              ))
                            ) : (
                              <NotFound text="No Slots Available" />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Footer */}
                <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
                  <WhiteButton
                    name="Cancel"
                    className="w-full h-14"
                    handleClick={() => {
                      setSlotModal(false);
                    }}
                  />
                  <DarkBlueButton
                    type="button"
                    name="Confirm"
                    className="w-full h-14"
                    handleClick={() => {
                      setSlotModal(false);
                    }}
                  />
                </div>
              </form>
            </Modal>
            {(formState.type === "booking" ||
              formState.type === "follow-up" ||
              queue_type === "Token") &&
              queue_type !== "Session" &&
              allSessions &&
              allSessions.length > 0 &&
              allSessions.filter((data) => data.label !== "Unavailable Session")
                .length > 0 &&
              !sessionExp && (
                <div>
                  <div className="flex flex-col w-full my-3">
                    <p className="text-modalText text-md mb-1 mt-8 font-bold">
                      {estimateVisitTime.includes(String(hospital_id))
                        ? "Estimate Visit Time"
                        : "Choose slot timings"}{" "}
                      <span className="text-red-500 text-sm relative bottom-1 font-medium">
                        *
                      </span>
                    </p>

                    <div>
                      <div className="flex flex-wrap justify-start my-3 gap-3">
                        {queue_type === "Token" ? (
                          formState.allTokens.length !== 0 &&
                          formState.allTokens[0] !== 0 ? (
                            formState.allTokens.map((item, index) => (
                              <React.Fragment key={index}>
                                <SelectToken
                                  selected={token === item}
                                  setToken={setToken}
                                  item={item}
                                />
                              </React.Fragment>
                            ))
                          ) : (
                            <div>
                              <p className="text-onLeave mb-3">
                                Maximum number of patients for this session has
                                reached. Please press on extend session to add
                                more bookings.
                              </p>
                              <div className="flex flex-row items-center space-x-3">
                                <button
                                  type="button"
                                  className="bg-nextPatient text-white px-4 lg:px-6 py-2 rounded hover:bg-blue-400 w-72"
                                  onClick={async () => {
                                    resetStates();
                                    console.log(extend);
                                    const req = {
                                      availability_id:
                                        formState.docSession?.value,
                                      adjustment_date: SelectedDate,
                                      session_start_time: moment(
                                        String(
                                          formState.docSession?.label
                                        )?.split(" - ")[0],
                                        "HH:mmA"
                                      ).format("HH:mm:ss"),
                                      extended_end_time: moment(
                                        String(
                                          formState.docSession?.label
                                        )?.split(" - ")[1],
                                        "HH:mmA"
                                      )
                                        .add(extend, "minutes")
                                        .format("HH:mm:ss"),
                                    };
                                    console.log(req);
                                    const res = await extendSession(req);
                                    const data = res.data.result;
                                    console.log(data);
                                    setFormState({
                                      ...formState,
                                      docSession: {
                                        value: data.availability_id,
                                        label: `${moment(
                                          data.session_start_time,
                                          "HH:mm:ss"
                                        ).format("hh:mmA")} - ${moment(
                                          data.extended_end_time,
                                          "HH:mm:ss"
                                        ).format("hh:mmA")}`,
                                      },
                                    });
                                  }}
                                >
                                  Extend session (
                                  {extend < 60 ? extend : extend / 60}
                                  {extend < 60 ? "min" : "hr"})
                                </button>
                                <WhiteButton
                                  name="+"
                                  handleClick={() => {
                                    if (extend < 120) setExtend(extend + 30);
                                  }}
                                  className="border-sbTextHover text-2xl !py-1 !px-3"
                                />
                                <WhiteButton
                                  name="-"
                                  handleClick={() => {
                                    if (extend > 30) setExtend(extend - 30);
                                  }}
                                  className="border-sbTextHover text-2xl !py-1 !px-4"
                                />
                              </div>
                            </div>
                          )
                        ) : formState.allSlots.length !== 1 ||
                          formState.allSlots[0] !== "" ? (
                          formState.allSlots.map((item, index) => (
                            <React.Fragment key={index}>
                              {index <= 4 && (
                                <SelectSlot
                                  item={item}
                                  setSlot={setBookingSlot}
                                  selected={
                                    bookingSlot?.includes(item) || false
                                  }
                                  people={1}
                                />
                              )}
                              {index === 5 && (
                                <button
                                  className="p-6 py-4 rounded-lg border-2 border-[#E5E5E5] flex flex-col justify-center items-center cursor-pointer bg-black  text-white w-full md:w-[50%] lg:w-[31%]"
                                  onClick={() => {
                                    setSlotModal(true);
                                  }}
                                >
                                  <p className="flex gap-3 items-center">
                                    {" "}
                                    See more slots <IoIosArrowForward />
                                  </p>
                                </button>
                              )}
                            </React.Fragment>
                          ))
                        ) : (
                          !load && (
                            <div>
                              <p className="text-onLeave mb-3">
                                Maximum number of patients for this session has
                                reached. Please press on extend session to add
                                more bookings.
                              </p>
                              <div className="flex flex-row items-center space-x-3">
                                <WhiteButton
                                  name="+"
                                  handleClick={() => {
                                    if (extend < 120) setExtend(extend + 30);
                                  }}
                                  className="border-[2px] border-black text-white bg-black text-2xl  hover:bg-black hover:text-white w-[10%] h-12 flex items-center justify-center"
                                />
                                <button
                                  type="button"
                                  className="bg-white text-black border-2 border-[#E5E5E5] px-4 lg:px-6 py-2 rounded-lg  h-12 w-[80%] hover:bg-queueHover hover:text-white"
                                  style={{ marginLeft: "0" }}
                                  onClick={async () => {
                                    const req = {
                                      availability_id:
                                        formState.docSession?.value,
                                      adjustment_date: SelectedDate,
                                      session_start_time: moment(
                                        String(
                                          formState.docSession?.label
                                        )?.split(" - ")[0],
                                        "HH:mmA"
                                      ).format("HH:mm:ss"),
                                      extended_end_time: moment(
                                        String(
                                          formState.docSession?.label
                                        )?.split(" - ")[1],
                                        "HH:mmA"
                                      )
                                        .add(extend, "minutes")
                                        .format("HH:mm:ss"),
                                    };

                                    const res = await extendSession(req);
                                    const data = res.data.result;

                                    fetchDocAvailability();
                                  }}
                                >
                                  Extend session (
                                  {extend < 60 ? extend : extend / 60}
                                  {extend < 60 ? "min" : "hr"})
                                </button>

                                <WhiteButton
                                  name="-"
                                  handleClick={() => {
                                    if (extend > 30) setExtend(extend - 30);
                                  }}
                                  className="border-[2px] border-black hover:bg-black text-2xl text-white bg-black hover:text-white w-[10%] h-12 flex items-center justify-center"
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className="flex gap-4 justify-between mt-8">
              <button
                className="p-6 py-4 rounded-lg border-[1px] border-[#335FE4] flex flex-col justify-center items-center cursor-pointer bg-white  text-black font-semibold w-[50%]"
                onClick={resetStates}
              >
                Cancel
              </button>
              <DarkBlueButton
                name="Book Appointment"
                className={
                  !sessionExp &&
                  allSessions &&
                  allSessions.length > 0 &&
                  allSessions.filter(
                    (data) => data.label !== "Unavailable Session"
                  ).length > 0
                    ? "w-[50%] !mr-0 "
                    : "w-[50%] !mr-0 !bg-gray-500 !hover:bg-gray-500 cursor-not-allowed"
                }
                handleClick={
                  !sessionExp &&
                  allSessions &&
                  allSessions.length > 0 &&
                  allSessions.filter(
                    (data) => data.label !== "Unavailable Session"
                  ).length > 0
                    ? handleBooking
                    : () => {}
                }
              />
            </div>
          </div>
        </div>
      </div>
      {load && <Loader />}
    </>
  );
};

export default AppointmentBooking;
