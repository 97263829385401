import React from "react";
import { EditDaySessions } from "../../services/types";
import moment from "moment";

const EachDaySession = ({
  day,
  daySchedule,
}: {
  day: string;
  daySchedule: EditDaySessions;
}) => {
  return (
    <div>
      <div className="md:grid md:grid-cols-12 py-4 border-b-[1px] border-doctorsBorder">
        <div className="col-span-2">
          <p className="text-dark font-medium mb-2">{day} -</p>
        </div>
        <div className="col-span-10 space-y-2">
          {daySchedule.sessions["morning"].start_time !== "" ? (
            <div className="flex flex-col md:flex-row md:space-x-1">
              <p className="text-dark text-sm truncate">Morning Session :</p>
              <p className="text-docDetail text-sm">
                <span>
                  {moment(
                    daySchedule.sessions["morning"].start_time,
                    "HH:mm:ss"
                  ).format("hh:mm A")}{" "}
                  -{" "}
                  {moment(
                    daySchedule.sessions["morning"].end_time,
                    "HH:mm:ss"
                  ).format("hh:mm A")}{" "}
                </span>
                <span className="text-dark">
                  | {daySchedule.sessions["morning"].max_patients_per_slot}{" "}
                  Patients | {daySchedule.sessions["morning"].wait_time.minutes}{" "}
                  mins
                </span>
              </p>
            </div>
          ) : (
            <p className="text-dark text-sm truncate">
              Morning Session :{" "}
              <span className="text-docDetail">No Session</span>
            </p>
          )}
          {daySchedule.sessions["afternoon"].start_time !== "" ? (
            <div className="flex flex-col md:flex-row md:space-x-1">
              <p className="text-dark text-sm truncate">Afternoon Session :</p>
              <p className="text-docDetail text-sm">
                <span>
                  {moment(
                    daySchedule.sessions["afternoon"].start_time,
                    "HH:mm:ss"
                  ).format("hh:mm A")}{" "}
                  -{" "}
                  {moment(
                    daySchedule.sessions["afternoon"].end_time,
                    "HH:mm:ss"
                  ).format("hh:mm A")}
                </span>{" "}
                <span className="text-dark">
                  | {daySchedule.sessions["afternoon"].max_patients_per_slot}{" "}
                  Patients |{" "}
                  {daySchedule.sessions["afternoon"].wait_time.minutes} mins
                </span>
              </p>
            </div>
          ) : (
            <p className="text-dark text-sm truncate">
              Afternoon Session :{" "}
              <span className="text-docDetail">No Session</span>
            </p>
          )}
          {daySchedule.sessions["evening"].start_time !== "" ? (
            <div className="flex flex-col md:flex-row md:space-x-1">
              <p className="text-dark text-sm truncate">Evening Session :</p>
              <p className="text-docDetail text-sm">
                <span>
                  {moment(
                    daySchedule.sessions["evening"].start_time,
                    "HH:mm:ss"
                  ).format("hh:mm A")}{" "}
                  -{" "}
                  {moment(
                    daySchedule.sessions["evening"].end_time,
                    "HH:mm:ss"
                  ).format("hh:mm A")}
                </span>{" "}
                <span className="text-dark">
                  | {daySchedule.sessions["evening"].max_patients_per_slot}{" "}
                  Patients | {daySchedule.sessions["evening"].wait_time.minutes}{" "}
                  mins
                </span>
              </p>
            </div>
          ) : (
            <p className="text-dark text-sm truncate">
              Evening Session :{" "}
              <span className="text-docDetail">No Session</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EachDaySession;
