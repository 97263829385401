import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { DocProfileData } from "../../../services/types";
import { toast } from "react-toastify";
import { generateRandomString, momentIN } from "../../../lib/utils/funcs";
import CloseButton from "../../atoms/buttons/CloseButton";
import DarkBlueButton from "../../atoms/buttons/DarkBlueButton";
import ReactModal from "react-modal";
import Cropper, { Area } from "react-easy-crop";
import { useHospDocData } from "../../../lib/contexts/HospitalDoctorContext";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { storage } from "../../../firebase";
import { modalStyles } from "../../../assets/styles/modalStyles";
import { updateDoctor } from "../../../lib/apis/doctor";
import WhiteButton from "../../atoms/buttons/WhiteButton";

const EditDocModal = ({
  closeModal,
  modalIsOpen,
  customStyles,
  data,
}: {
  closeModal: () => void;
  modalIsOpen: boolean;
  customStyles: any;
  data: DocProfileData | undefined;
}) => {
  const { hospitalID } = useHospDocData();

  const [docData, setDocData] = useState<DocProfileData>();
  const randomString = generateRandomString(10);
  const [visible, setVisible] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [imgSrc, setImgSrc] = useState<string | ArrayBuffer | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<
    Area | undefined
  >();

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result);
        setVisible(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const uploadCroppedImage = async (croppedAreaPixels: Area | undefined) => {
    if (croppedAreaPixels !== undefined) {
      const imageSrc = imgSrc as string;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.src = imageSrc;

      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      image.onload = () => {
        if (ctx) {
          ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            croppedAreaPixels.width,
            croppedAreaPixels.height
          );

          canvas.toBlob((blob) => {
            if (!blob) {
              console.error("Canvas is empty");
              return;
            }
            const file = new File(
              [blob],
              docData?.full_name
                ? hospitalID + "_" + docData?.full_name
                : hospitalID + "_" + randomString,
              {
                type: "image/png",
              }
            );
            const storageRef = ref(
              storage,
              `doc_profile_pictures/${file.name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadStatus(`Upload is ${progress.toFixed(2)}% done`);
              },
              (error) => {
                setUploadStatus(`Upload failed: ${error.message}`);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  setUploadStatus("File successfully uploaded");
                  console.log("Download URL:", downloadURL);
                  setDocData((prevState) => {
                    if (prevState) {
                      return { ...prevState, profile_picture: downloadURL };
                    }
                    return prevState;
                  });
                });
              }
            );
          }, "image/png");
        }
      };
    }
  };

  useEffect(() => {
    setDocData(data);
  }, [modalIsOpen]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setDocData((prevState) => {
      if (prevState) {
        const newValue =
          name === "doctor_id" ||
          name === "mapping_id" ||
          name === "experience_years" ||
          name === "created_by"
            ? Number(value)
            : value;

        return { ...prevState, [name]: newValue };
      }
      return prevState;
    });
  };

  const handleSubmit = async () => {
    if (docData && docData.doctor_id) {
      const doc_id = docData?.doctor_id;
      const { doctor_id, contact_number, email, updated_at, ...rest } = docData;

      const res = await updateDoctor(doc_id, rest);
      if (res?.status === 200) {
        toast.success("Doctor updated successfully!");
        closeModal();
      } else toast.error("Error while updating doctor data.");
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      {/* Header */}
      <div className="px-6 py-3 flex flex-row items-center justify-between border-b-[0.5px] border-sbBorder">
        <p className="text-dark text-md font-semibold">Edit Basic Info</p>
        <CloseButton handleClick={closeModal} />
      </div>

      {/* Body */}

      <div className="p-0 max-h-96 overflow-y-auto">
        <div className="px-6 py-5 bg-lightGrey">
          <p className="font-light text-modalText text-sm mb-1">
            Profile Picture
          </p>
          <input type="file" accept="image/*" onChange={onSelectFile} />
          <ReactModal
            isOpen={visible}
            onRequestClose={() => setVisible(false)}
            contentLabel="Crop Image"
            style={modalStyles}
          >
            <div className="h-[60vh] w-[40vw] px-6 py-3  flex justify-end items-end">
              <Cropper
                image={String(imgSrc)}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
              <button
                onClick={() => {
                  uploadCroppedImage(croppedAreaPixels);
                  setVisible(false);
                }}
                className="rounded-lg opacity-80 bg-sbTextHover px-4 py-2 text-white hover:bg-queueHover mr-3"
              >
                Crop & Upload
              </button>
            </div>
          </ReactModal>
          <p
            className={`font-light ${
              uploadStatus.includes("success")
                ? "text-available"
                : uploadStatus.includes("failed")
                ? "text-onLeave"
                : uploadStatus.includes("done")
                ? "text-yellow-400"
                : "text-modalText"
            } text-sm mb-3`}
          >
            {uploadStatus}
          </p>
          <p className="font-light text-modalText text-sm mb-1 ">Full Name</p>
          <input
            className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
            placeholder="Enter full name"
            type="text"
            value={docData?.full_name}
            onChange={handleInputChange}
            name="full_name"
          />
          <div className="flex flex-row mt-3">
            <div className="flex flex-col w-full mr-3">
              <p className="font-light text-modalText text-sm mb-1 ">
                Date of Birth
              </p>
              <input
                className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
                placeholder="Enter date of birth"
                type="date"
                name="date_of_birth"
                min="1954-01-01"
                max="2000-01-01"
                value={momentIN(docData?.date_of_birth).format("YYYY-MM-DD")}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col w-full">
              <p className="font-light text-modalText text-sm mb-1 ">Gender</p>
              <div className="flex flex-row space-x-3">
                <button
                  className={`${
                    docData?.gender === "Male"
                      ? "bg-editBG text-white"
                      : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                  } px-4 py-2 rounded-[20px]`}
                  //@ts-ignore
                  onClick={() => setDocData({ ...docData, gender: "Male" })}
                >
                  Male
                </button>
                <button
                  className={`${
                    docData?.gender === "Female"
                      ? "bg-editBG text-white"
                      : "border-[1px] border-editBG text-editBG hover:bg-purple-400 hover:text-white"
                  } px-4 py-2 rounded-[20px]`}
                  //@ts-ignore
                  onClick={() => setDocData({ ...docData, gender: "Female" })}
                >
                  Female
                </button>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p className="font-light text-modalText text-sm mb-1 ">
              Qualification
            </p>
            <input
              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
              placeholder="Enter qualification"
              type="text"
              name="qualification"
              value={docData?.qualification}
              onChange={handleInputChange}
            />
          </div>
          <div className="mt-3">
            <p className="font-light text-modalText text-sm mb-1 ">
              Specialization
            </p>
            <select
              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
              name="specialization"
              onChange={handleInputChange}
              required
            >
              <option value={docData?.specialization} disabled selected hidden>
                {docData?.specialization}
              </option>
              <option value="Cardiology">Cardiology</option>
              <option value="Orthopaedics">Orthopaedics</option>
              <option value="Dermatology">Dermatology</option>
              <option value="Pediatrics">Pediatrics</option>
              <option value="Gynaecology">Gynaecology</option>
              <option value="Neurology">Neurology</option>
            </select>
          </div>
          <div className="mt-3">
            <p className="font-light text-modalText text-sm mb-1 ">
              Phone Number
            </p>
            <input
              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full bg-white"
              placeholder="Enter phone number"
              name="contact_number"
              value={docData?.contact_number}
              minLength={10}
              maxLength={10}
              onChange={handleInputChange}
              disabled
            />
          </div>
          <div className="mt-3">
            <p className="font-light text-modalText text-sm mb-1 ">Email Id</p>
            <input
              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
              placeholder="Enter email id"
              type="email"
              name="email"
              value={docData?.email}
              onChange={handleInputChange}
              disabled
            />
          </div>
          <div className="mt-3">
            <p className="font-light text-modalText text-sm mb-1 ">
              Experience Years
            </p>
            <input
              className="rounded-lg px-3 py-2 border-[0.5px] border-sbBorder w-full"
              placeholder="Enter years of experience"
              type="text"
              name="experience_years"
              value={docData?.experience_years}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="px-6 py-3 flex flex-row items-center justify-end border-t-[0.5px] border-sbBorder">
        <WhiteButton
          name="Cancel"
          className="w-full h-14"
          handleClick={closeModal}
        />
        <DarkBlueButton
          name="Save"
          handleClick={() => handleSubmit()}
          className="w-full h-14"
        />
      </div>
    </Modal>
  );
};

export default EditDocModal;
