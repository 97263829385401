import React, { useEffect, useState } from "react";
import Header from "../components/organisms/Header";
import Tabs from "../components/molecules/Tabs";
import Table from "../components/organisms/Table";
import { useHospDocData } from "../lib/contexts/HospitalDoctorContext";
import Loader from "../components/molecules/Loader";
import { getRouteSegment } from "../lib/utils/funcs";
import { Search } from "../assets/icons/Icons";
import {
  getBookingListByHospitalId,
  getBookingRangeByHospitalId,
} from "../lib/apis/booking";
import { usePatientBooking } from "../lib/contexts/PatientBookingContext";
import { hitRefreshToken } from "../lib/apis/user";
import { getCookie, setCookie } from "../lib/utils/cookies";
import { useUserData } from "../lib/contexts/UserContext";
import {
  allappointmentHeaders,
  appointmentHeaders,
} from "../assets/data/headers";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Booking } from "../lib/utils/types";
import RescheduleModal from "../components/organisms/modals/RescheduleModal";
import { modalStyles } from "../assets/styles/modalStyles";
import { Option } from "react-dropdown";

const Appointments = () => {
  const id = getRouteSegment(1);
  const navigate = useNavigate();
  const accessToken = getCookie("accessToken");
  const refreshToken = getCookie("refreshToken");

  const { userData } = useUserData();
  const { hospitalID, setHospitalID, hospData } = useHospDocData();
  const { appointmentsData, setAppointmentsData } = usePatientBooking();
  const [rescheduleVisible, setRescheduleVisible] = useState(false);
  const [availability_id, setAvailabilityId] = useState<string>();
  const [mapping_id, setMappingId] = useState<string>();
  const [bookingId, setBookingId] = useState<string>();
  const [search, setSearch] = useState("");
  const [allAppointments, setAllAppointments] = useState<
    Booking[] | undefined
  >();

  useEffect(() => {
    setHospitalID(id);
  }, [id]);

  function filterUniqueBookings(bookings: Booking[]): Booking[] {
    const uniqueBookings = new Map<string, Booking>();
    bookings.forEach((booking) => {
      if (!uniqueBookings.has(booking.booking_id)) {
        uniqueBookings.set(booking.booking_id, booking);
      }
    });
    return Array.from(uniqueBookings.values());
  }

  useEffect(() => {
    const fetchAllAppointments = async () => {
      const appointment_data = await getBookingRangeByHospitalId({
        hospital_id: hospitalID,
        startDate: moment().add(1, "days").format("YYYY-MM-DD"),
        endDate: "2024-12-30",
        doctor_id: "",
      });

      if (appointment_data?.status === 200) {
        setAllAppointments(filterUniqueBookings(appointment_data.data.result));
      } else {
        setAllAppointments(undefined);
      }
    };
    fetchAllAppointments();
  }, [rescheduleVisible, setAppointmentsData, mapping_id]);

  useEffect(() => {
    const patientsData = async () => {
      const res = await getBookingListByHospitalId(
        hospitalID,
        userData?.doctor_id
      );
      if (res?.status === 200) {
        setAppointmentsData(res.data.result);
        console.log("appointmentsData", res.data.result);
      } else if (res?.status === 403 && accessToken && refreshToken) {
        const refresh_data = await hitRefreshToken(accessToken, refreshToken);
        if (refresh_data?.status === 200) {
          console.log("Refresh");
          setCookie("accessToken", refresh_data.data.result.access_token, 30);
          setCookie("refreshToken", refresh_data.data.result.refresh_token, 30);
          const res = await getBookingListByHospitalId(
            hospitalID,
            userData?.doctor_id
          );
          if (res?.status === 200) setAppointmentsData(res.data.result);
        } else {
          setAppointmentsData(undefined);
        }
      } else {
        setAppointmentsData(undefined);
      }
    };

    patientsData();
  }, [hospData, rescheduleVisible, setAppointmentsData, mapping_id]);

  const tabsConfig = [
    {
      label: "Today's Appointments",
      count:
        "(" +
        (appointmentsData
          ? appointmentsData.filter(
              (item) =>
                item.reference_id
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.patient_full_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.patient_contact_number.includes(search.toLowerCase())
            ).length
          : "0") +
        ")",
      content:
        (appointmentsData &&
          appointmentsData.filter(
            (item) =>
              item.reference_id.toLowerCase().includes(search.toLowerCase()) ||
              item.patient_full_name
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              item.patient_contact_number.includes(search.toLowerCase())
          ).length === 0) ||
        appointmentsData === undefined ? (
          <div className="bg-white flex justify-center items-center min-h-[75vh]">
            <p className="text-center">No Appointments Found.</p>
          </div>
        ) : (
          <div className="min-h-[45vh] md:min-h-auto max-h-[65vh] md:max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
            <Table
              type="appointments"
              headers={appointmentHeaders}
              bookings={
                search
                  ? appointmentsData?.filter(
                      (item) =>
                        item.reference_id
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name.includes(search.toLowerCase())
                    )
                  : appointmentsData
              }
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              setMappingId={setMappingId}
              setAvailabilityId={setAvailabilityId}
            />
          </div>
        ),
    },
    {
      label: "All Appointments",
      count:
        "(" +
        (allAppointments
          ? allAppointments.filter(
              (item) =>
                item.reference_id
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.patient_full_name
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                item.patient_contact_number.includes(search.toLowerCase())
            ).length
          : "0") +
        ")",
      content:
        (allAppointments &&
          allAppointments.filter(
            (item) =>
              item.reference_id.toLowerCase().includes(search.toLowerCase()) ||
              item.patient_full_name
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              item.patient_contact_number.includes(search.toLowerCase())
          ).length === 0) ||
        allAppointments === undefined ? (
          <div className="bg-white flex justify-center items-center min-h-[75vh]">
            <p className="text-center">No Appointments Found.</p>
          </div>
        ) : (
          <div className="min-h-[45vh] md:min-h-auto max-h-[65vh] md:max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
            <Table
              type="allappointments"
              headers={allappointmentHeaders}
              bookings={
                search
                  ? allAppointments?.filter(
                      (item) =>
                        item.reference_id
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        item.patient_full_name.includes(search.toLowerCase())
                    )
                  : allAppointments
              }
              setVisible={setRescheduleVisible}
              setBookingID={setBookingId}
              setMappingId={setMappingId}
              setAvailabilityId={setAvailabilityId}
            />
          </div>
        ),
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <Header header value={"Appointments"} />
      <div className="flex flex-col bg-white rounded-t-lg mt-6 ml-7 mr-8 pt-4 border-[0.5px] border-doctorsBorder">
        <Tabs
          tabsConfig={tabsConfig}
          rightSide={
            <div className="flex justify-end items-center m-3 md:pr-5 md:mt-0 md:mb-[7px]">
              <button
                className="rounded-lg opacity-80 px-4 py-2 text-white bg-sbTextHover hover:bg-queueHover mr-3"
                onClick={() =>
                  navigate(
                    "/" + hospitalID + "/appointments/book-an-appointment"
                  )
                }
              >
                Create Appointment
              </button>
              <div className="flex items-center rounded-lg border-[0.5px] border-sbBorder bg-white md:mr-5">
                <input
                  className="border-0 p-0 px-4 py-2 border-sbBorder opacity-80 rounded-lg focus:outline-none w-5/6"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <p className="w-1/6">
                  <Search />
                </p>
              </div>
            </div>
          }
        />
      </div>
      <RescheduleModal
        modalIsOpen={rescheduleVisible}
        closeModal={() => {
          setTimeout(() => {
            setRescheduleVisible(false);
          }, 500);
        }}
        customStyles={modalStyles}
        session={availability_id}
        booking_id={bookingId}
        mappingId={mapping_id}
        type="each"
        booking_ids={[]}
      />
      {userData === undefined && <Loader />}
    </div>
  );
};

export default Appointments;
